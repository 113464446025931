import React, { useState } from 'react';
import * as moment from 'moment';
import css from './RepeatWeekly.module.css';
import cls from 'classnames';

import { cssText as text, cssColor as colors } from '_fsd/shared';

const weekDays = moment.weekdaysMin();

const numbersToWeekdays = (numbers) => {
  const days = weekDays;
  return numbers.map((number) => {
    const dayIndex = number === 6 ? 0 : number + 1;
    return days[dayIndex];
  });
};

export const RepeatWeekly = ({ onChange, byweekday }) => {
  const [value, setValue] = useState(numbersToWeekdays(byweekday));

  const handleClick = (day) => () => {
    const newValue = value.includes(day) ? value.filter((d) => d !== day) : [...value, day];
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={css.checkBoxGroup}>
      {weekDays.map((day) => (
        <div
          onClick={handleClick(day)}
          key={day}
          value={day}
          className={cls(
            css.checkBox,
            text.s14h20w4,
            colors.title85,
            value.includes(day) ? css.checkBoxSelected : undefined
          )}>
          {day}
        </div>
      ))}
    </div>
  );
};
