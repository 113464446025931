import { RRule } from 'rrule';

export const parseStringToRrule = (string) => {
  // Split the rule into lines
  const lines = string.split('\n');
  // Filter out lines starting with "EXDATE:"
  const filteredLines = lines.filter((line) => !line.startsWith('EXDATE:'));
  // Join the filtered lines back into a single string
  const filteredRule = filteredLines.join('\n');
  // Convert the filtered rule string to text
  return [
    RRule.fromString(filteredRule),
    lines.filter((line) => !line.startsWith('EXDATE:')).join('\n')
  ];
};
