import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Layout, Typography } from 'antd';

import PhoneInputNew from '../../../PhoneInput/PhoneInput';

import './style.scss';
const { Title } = Typography;
const { Content } = Layout;

const PersonalDetails = ({
  form,
  phoneNumberError,
  isForVet,
  disablePhone,
  errors,
  handleReassignEmailButton,
  disableEmail,
  isPetOwner
}) => {
  Form.useWatch('phonePrefix', form);
  Form.useWatch('phoneNumber', form);
  const prefix = form.getFieldValue('phonePrefix');
  const number = form.getFieldValue('phoneNumber');
  const userId = form.getFieldValue('uid');
  const { t } = useTranslation();
  return (
    <Content className="patient-details-wrapper personal-details-wrapper">
      <Title className="page-heading" level={5}>
        {isForVet
          ? t('scheduling_stepper.vet_personal_step_title')
          : t('personal_details.add.information.about.yourself')}
      </Title>
      <Form.Item
        name="firstName"
        rules={[
          { required: true, whitespace: true },
          { max: 25, message: t('too.much.symbols') }
        ]}
        label={t('personal_details.firstName')}>
        <Input placeholder="Maria" maxLength={25} />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[
          { required: isPetOwner, whitespace: true },
          { max: 25, message: t('too.much.symbols') }
        ]}
        label={t('personal_details.lastName')}>
        <Input placeholder="Morrison" maxLength={25} />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: isPetOwner, type: 'email', whitespace: true },
          { max: 254, message: t('too.much.symbols') }
        ]}
        label={t('personal_details.email')}>
        <Input disabled={disableEmail} placeholder="Mariamorrison@gmail.com" maxLength={254} />
      </Form.Item>
      {errors?.email && <span className="email-error">{errors.email}</span>}
      {errors?.email && errors.canBeReassigned && (
        <button type="link" className="email-action" onClick={handleReassignEmailButton}>
          Claim this email
        </button>
      )}
      {!userId && (
        <Form.Item
          name="phoneNumber"
          rules={[
            { required: isPetOwner },
            { pattern: /^[0-9]{8,14}$/, message: t('phone.input.invalid_number') }
          ]}
          label={t('personal_details.phone.number')}>
          <PhoneInputNew
            disabled={disablePhone}
            code={prefix}
            number={number}
            onChange={(prefix, number) => {
              form.setFieldsValue({ phonePrefix: prefix, phoneNumber: number });
            }}
            error={phoneNumberError}
          />
        </Form.Item>
      )}
    </Content>
  );
};

export default memo(PersonalDetails);
