import { useMutation } from '@apollo/client';
import { updateRecurringTimeSlotMutation } from 'gql';

export const useUpdateRecurringTimeSlot = () => {
  const [updateRecurringTimeSlot] = useMutation(updateRecurringTimeSlotMutation, {
    refetchQueries: ['getAuthVet']
  });

  return { updateRecurringTimeSlot };
};
