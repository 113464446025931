import { gql, useQuery } from '@apollo/client';

export const useTimeslot = (uid) => {
  const { data: { getTimeslot } = {}, ...rest } = useQuery(queryGetTimeSlot, {
    variables: { record: { uid } },
    skip: !uid
  });

  return { data: getTimeslot, ...rest };
};

export const queryGetTimeSlot = gql`
  query getTimeslot($record: GetTimeSlotRecord!) {
    getTimeslot(record: $record) {
      uid
      startTime
      endTime
      date
      weekDay
      rule
      name
      type
      maxAppointments
      isRecurring
      isSingle
      maxAppointments
      startPoint {
        lat
        lng
        description
        zipCode
        city
        countryCode
        street
        houseNumber
      }
      endPoint {
        lat
        lng
        description
        zipCode
        city
        countryCode
        street
        houseNumber
      }
      vet {
        uid
        user {
          uid
          firstName
          lastName
          avatarUrl
          userSettingStartPoint
          userSettings {
            uid
            itemValue
            setting {
              description
            }
          }
        }
      }
    }
  }
`;
