export const addressSerializer = ({ address, startPoint }) => {
  return {
    city: address?.city,
    countryCode: address?.countryCode,
    street: address?.street,
    description: address?.description,
    lat: address?.lat,
    lng: address?.lng,
    zipCode: address?.zipCode,
    houseNumber: parseInt(startPoint?.houseNumber || address?.houseNumber)
  };
};

export const getPointsFromVet = (vet) => {
  const start = vet?.user?.userSettings?.find(
    (s) => s?.setting?.description === 'DEFAULT_START_POINT'
  )?.itemValue;
  const end = vet?.user?.userSettings?.find(
    (s) => s?.setting?.description === 'DEFAULT_START_POINT'
  )?.itemValue;

  return { start: JSON.parse(start || '{}'), end: JSON.parse(end || '{}') };
};
