import { gql, useMutation } from '@apollo/client';

export const useReassignVetForTimeslot = (options) => {
  return useMutation(mutationReassignVetForTimeslot, options);
};

export const mutationReassignVetForTimeslot = gql`
  mutation mutation($record: ReassignVetRecord!) {
    reassignVet(record: $record)
  }
`;
