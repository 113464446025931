import React, { useState, memo, useEffect } from 'react';

import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import AddTimeslotModal from '../../add-timeslot-modal/AddTimeslotModal';
import noTimeslotsImg from 'assets/icons/no-timeslot.svg';
import useTimeslots from './useTimeslots';

import * as S from './components';
import './styles.css';
import { sortByStartTime } from '../../../../utils/helpers';

const PAGE_SIZE = 3;
const Timeslots = ({
  endPoint,
  startPoint,
  clientLimit,
  calendarDate,
  timeslotsData,
  refetchAuthVet,
  appointmentsData,
  spotCalendarDate,
  setSelectedTimeslot,
  registerOrAdjustment,
  setSelectedDateTimeSlots,
  setIsAppointmentsModalOpen,
  vet
}) => {
  const [isAddTimeslotModalOpen, setIsAddTimeslotModalOpen] = useState(false);
  const [updateTimeslotData, setUpdateTimeslotData] = useState(null);

  const { t } = useTranslation();
  const { timeslotsColumn } = useTimeslots(
    setUpdateTimeslotData,
    calendarDate,
    refetchAuthVet,
    setSelectedTimeslot
  );

  const modalIsVisible = isAddTimeslotModalOpen || updateTimeslotData;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedTimeslot(timeslotsData.find((t) => t.uid === newSelectedRowKeys[0])?.uid);
  };

  useEffect(() => {
    if (timeslotsData?.length && calendarDate) {
      setSelectedRowKeys([timeslotsData[0]?.uid]);
      setSelectedTimeslot(timeslotsData[0]?.uid);
    }
  }, [calendarDate, timeslotsData]);

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange
  };
  return (
    <>
      {modalIsVisible && (
        <AddTimeslotModal
          isCopying={updateTimeslotData && !updateTimeslotData?.uid}
          vet={vet}
          closeModal={() => {
            setIsAddTimeslotModalOpen(false);
            setUpdateTimeslotData(null);
          }}
          endPoint={endPoint}
          startPoint={startPoint}
          clientLimit={clientLimit}
          calendarDate={calendarDate}
          refetchAuthVet={refetchAuthVet}
          appointmentsData={appointmentsData}
          spotCalendarDate={spotCalendarDate}
          updateTimeslotData={updateTimeslotData}
          setSelectedTimeslot={setSelectedTimeslot}
          registerOrAdjustment={registerOrAdjustment}
          setSelectedDateTimeSlots={setSelectedDateTimeSlots}
        />
      )}

      <S.Wrapper style={{ minHeight: '380px' }}>
        <S.Header>
          <S.Flex>
            <S.Heading>{t('timeslots')}</S.Heading>
            <S.DateText>{dayjs(calendarDate).format('MMMM DD, YYYY')}</S.DateText>
          </S.Flex>

          {!!timeslotsData?.length && (
            <Button
              type="secondary"
              className="timeslot-button"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsAddTimeslotModalOpen(true);
              }}>
              {t('add.timeslot')}
            </Button>
          )}
        </S.Header>

        {!!timeslotsData?.length ? (
          <Table
            rowSelection={rowSelection}
            pagination={timeslotsData?.length > PAGE_SIZE ? { pageSize: PAGE_SIZE } : false}
            dataSource={sortByStartTime(timeslotsData)}
            columns={timeslotsColumn}
            onRow={(record) => ({
              onClick: () => onSelectChange([record?.uid])
            })}
          />
        ) : (
          <S.NoTimeslotsWrapper>
            <S.NoTimeslotsImg src={noTimeslotsImg} />
            <Button
              type="secondary"
              className="timeslot-button"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsAddTimeslotModalOpen(true);
              }}>
              {t('add.timeslot')}
            </Button>
          </S.NoTimeslotsWrapper>
        )}
      </S.Wrapper>
    </>
  );
};

export default memo(Timeslots);
