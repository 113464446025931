import { useQuery } from '@apollo/client';
import { getVetByUidQuery } from 'gql';

export const useGetVetByUid = (uid, setVet) => {
  const { data, ...rest } = useQuery(getVetByUidQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      record: {
        uid
      }
    }
  });

  return { vet: data?.getVetByUid, ...rest };
};
