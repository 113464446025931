import React, { memo, useState } from 'react';
import { Appointments as AppointmentsNew } from '../../../widgets';
import { NewAppModalWrapper } from './NewAppModalWrapper';
import Appointments from '../../../../pages/vet/routes/appointments/Appointments';
import EditAppointmentAddressModal from '../../../../pages/vet/edit-appointment-address-modal/EditAppointmentAddressModal';
import NewClientModal from '../../../../pages/vet/new-appointment-modal/NewClientModal';
import useCalendar from '../../../../pages/calendar/useCalendar';

const AppWrapperComponent = ({
  isTimeslotsLoading,
  isAppsLoading,
  view,
  vet,
  apps,
  activeTimeslot,
  appRoute,
  route,
  calendarDate,
  refetchApps,
  vetAdmin
}) => {
  const [isNewAppointmentsModalOpen, setIsNewAppointmentsModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [appointmentAddressToEdit, setAppointmentAddressToEdit] = useState(false);
  const [isNewClientModalOpen, setIsNewClientModalOpen] = useState(false);
  const { sendRequestCode, checkExistingUser } = useCalendar();

  return (
    <>
      <Appointments
        setAppointmentAddressToEdit={setAppointmentAddressToEdit}
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        getAppointmentsByTimeslotUid={() => {
          refetchApps();
        }}
        selectedTimeslot={activeTimeslot}
        selectedTimeslotId={activeTimeslot?.uid}
        fullTimeSlots={vet?.fullTimeSlots}
        calendarDate={calendarDate}
        timeSlots={vet?.timeSlots}
        vetId={vet?.uid}
        vet={vet}
      />
      <AppointmentsNew
        isAppsLoading={isAppsLoading}
        isTimeslotsLoading={isTimeslotsLoading}
        forceSkip
        apps={apps}
        canCreateTS={view === 'timeslots' && activeTimeslot}
        onClickAddress={(app) => setAppointmentAddressToEdit(app)}
        selectedTimeslot={activeTimeslot}
        vet={activeTimeslot?.vet}
        timeSlotId={activeTimeslot?.uid}
        selectedRoute={appRoute?.uid}
        date={calendarDate.format('YYYY-MM-DD')}
        setSelectedAppointment={setSelectedAppointment}
        routeStatus={route?.status}
        setIsNewAppointmentsModalOpen={setIsNewAppointmentsModalOpen}
        vetAdmin={vetAdmin}
      />
      {isNewAppointmentsModalOpen && (
        <NewAppModalWrapper
          vet={vet}
          apps={apps}
          refetchApps={() => refetchApps()}
          isNewAppointmentsModalOpen={isNewAppointmentsModalOpen}
          selectedTimeslot={activeTimeslot}
          calendarDate={calendarDate}
          setIsNewClientModalOpen={setIsNewClientModalOpen}
          close={() => setIsNewAppointmentsModalOpen(false)}
          route={route}
        />
      )}
      {isNewClientModalOpen && (
        <NewClientModal
          vet={vet}
          calendarDate={calendarDate}
          sendRequestCode={sendRequestCode}
          selectedTimeslot={activeTimeslot}
          checkExistingUser={checkExistingUser}
          selectedWorkingArea={activeTimeslot}
          onRequestClose={() => setIsNewClientModalOpen(false)}
          goBack={() => {
            setIsNewClientModalOpen(false);
            setIsNewAppointmentsModalOpen(false);
          }}
          getAppointmentsByTimeslotUid={() => {
            refetchApps();
          }}
        />
      )}
      {appointmentAddressToEdit && (
        <EditAppointmentAddressModal
          getAppointmentsByTimeslotUid={() => refetchApps()}
          onRequestClose={() => setAppointmentAddressToEdit(null)}
          appointment={appointmentAddressToEdit}
        />
      )}
    </>
  );
};

export const AppWrapper = memo(AppWrapperComponent);
