import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClinicAdminAddAreaTutorial, CreateWorkingArea, useCreateAreaModal } from '_fsd/widgets';
import { Modal, Button, ButtonColors, cssText } from '_fsd/shared';
import {
  useFilters,
  WorkingAreasFilters,
  WorkingAreasMapView,
  WorkingAreasOrders,
  WorkingAreasScroll
} from '_fsd/features';
import {
  AREA_TYPE_DB,
  getLatLng,
  useFindWorkingAreasInfinityScroll,
  useRemoveWorkingArea
} from '_fsd/entities';
import { parseUserSettingStartPoint } from '_fsd/entities/vet';
import css from './WorkingAreaPage.module.css';
import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import AuthContext from 'contexts/AuthContext';

const TAKE_LIMIT = 1000;
export const WorkingAreaPage = (props) => {
  const { t } = useTranslation();
  const [view, setView] = useState('grid'); // grid | map
  const [filters, setFilters] = useFilters({
    take: TAKE_LIMIT,
    order: 'createdAt',
    record: {
      type: [AREA_TYPE_DB.ZIP, AREA_TYPE_DB.POLYGON]
    }
  });
  const { data, loading } = useFindWorkingAreasInfinityScroll(filters);
  const { createAreaModal, setCreateAreaModal } = useCreateAreaModal({
    defaultOpen: false
  });
  const [removeAreaModal, setRemoveAreaModal] = useState(false);
  const [removeArea] = useRemoveWorkingArea();

  const { user } = useContext(AuthContext);
  const settings = parseUserSettingStartPoint(user?.userSettings);
  const defaultCenter = getLatLng(settings);

  const onRemoveArea = () => {
    setRemoveAreaModal(false);
    if (removeAreaModal?.uid) {
      removeArea({
        variables: {
          id: removeAreaModal.uid
        }
      }).then(() => {
        message.success('Area was removed');
      });
    }
  };

  const loadMore = useCallback(() => {
    setFilters({ skip: filters.skip + TAKE_LIMIT });
  }, [setFilters, filters]);

  const displayTutorial =
    filters.isSaved &&
    data?.total === 0 &&
    !filters?.record.name &&
    filters?.skip === 0 &&
    !loading;

  return (
    <div className={css.container}>
      {displayTutorial ? (
        <ClinicAdminAddAreaTutorial addArea={() => setCreateAreaModal(true)} />
      ) : null}
      {!displayTutorial ? (
        <div className={css.areas}>
          <div className={css.sticky}>
            <WorkingAreasFilters
              loading={loading}
              total={data.total}
              filters={filters}
              setFilters={setFilters}
              onCreateArea={() => setCreateAreaModal(true)}
            />
            <div className={css.controls}>
              <div className={css.toggle}>
                <Button
                  type={view === 'grid' ? 'secondary' : 'default'}
                  colorScheme={ButtonColors.BLUE}
                  onClick={() => setView('grid')}>
                  {t('settings_page.service_area.areas.view_grid')}
                </Button>
                <Button
                  type={view === 'map' ? 'secondary' : 'default'}
                  colorScheme={ButtonColors.BLUE}
                  onClick={() => setView('map')}>
                  {t('settings_page.service_area.areas.view_map')}
                </Button>
              </div>
              <WorkingAreasOrders setFilters={setFilters} filters={filters} />
            </div>
            <span className={cssText.s14w4l24}>
              {t('settings_page.service_area.areas.total_areas', { total: data.total || 0 })}
            </span>
          </div>
          <div className={css.view}>
            {view === 'grid' ? (
              <WorkingAreasScroll
                onRemove={setRemoveAreaModal}
                onEdit={setCreateAreaModal}
                loading={loading}
                data={data}
                filters={filters}
                loadMore={loadMore}
              />
            ) : null}
            {view === 'map' ? (
              <WorkingAreasMapView
                defaultCenter={defaultCenter}
                data={data?.data}
                loading={loading}
                handleClickArea={setCreateAreaModal}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {createAreaModal && (
        <CreateWorkingArea
          defaultCenter={defaultCenter}
          createAreaModal={createAreaModal}
          onClose={(workingArea) => {
            if (workingArea) {
              setFilters({ skip: 0 });
            }
            setCreateAreaModal(null);
          }}
        />
      )}
      <Modal
        icon={<DeleteOutlined />}
        hideClose
        title={t('settings_page.service_area.remove_modal.title')}
        isOpen={removeAreaModal}
        onConfirm={() => onRemoveArea()}
        onClose={() => setRemoveAreaModal()}
        footerProps={{
          buttonCancelProps: {
            type: 'primary',
            colorScheme: ButtonColors.ORANGE
          },
          buttonConfirmProps: {
            type: 'default'
          },
          titleConfirm: t('settings_page.service_area.remove_modal.button_confirm')
        }}>
        {t('settings_page.service_area.remove_modal.content')}
      </Modal>
    </div>
  );
};
