import React, { useState, useContext } from 'react';
import css from './VetTimeSlotsPage.module.css';
import AuthContext from 'contexts/AuthContext';

import { Modal } from '_fsd/shared';
import { RemoveRecurringTimeSlot } from '_fsd/features/recurring-timeslot-remove';
import { useRemoveRecurringTimeSlot, useTimeSlotsByVetId } from '_fsd/entities/timeslot';
import { RecurringTimeslotListCreatable } from '_fsd/widgets/recurring-timeslot-list-creatable';
import { RecurringTimeSlotForm } from '_fsd/widgets/recurring-timeslot-form';

export const VetTimeSlotsPage = (props) => {
  const [isRecurringTimeSlotPageOpen, setIsRecurringTimeSlotPageOpen] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isCopying, setIsCopying] = useState(false);
  const [timeSlotIdToDelete, setTimeSlotIdToDelete] = useState(null);
  const { user } = useContext(AuthContext);

  const {
    data: timeSlots,
    loading,
    refetch: refetchTimeSlots
  } = useTimeSlotsByVetId({ vetId: user.vet.uid, isRecurring: true, isArchived: false });

  const { handleRemoveTimeSlot } = useRemoveRecurringTimeSlot({
    timeSlotIdToDelete,
    refetch: refetchTimeSlots
  });

  return (
    <>
      {isRecurringTimeSlotPageOpen ? (
        <RecurringTimeSlotForm
          selectedTimeSlot={selectedTimeSlot}
          goBack={() => {
            setIsRecurringTimeSlotPageOpen(false);
            setSelectedTimeSlot(null);
            setIsCopying(false);
          }}
          vetId={user.vet.uid}
          refetchTimeSlots={refetchTimeSlots}
          isCopying={isCopying}
        />
      ) : (
        <div className={css.container}>
          {user ? (
            <>
              <RecurringTimeslotListCreatable
                onRemove={(id) => setTimeSlotIdToDelete(id)}
                timeSlots={timeSlots}
                loading={loading}
                onClickNewRecurringTimeSlot={() => setIsRecurringTimeSlotPageOpen(true)}
                setSelectedTimeSlot={setSelectedTimeSlot}
                setIsRecurringTimeSlotPageOpen={setIsRecurringTimeSlotPageOpen}
                refetchTimeSlots={refetchTimeSlots}
                setIsCopying={setIsCopying}
              />
            </>
          ) : null}
        </div>
      )}
      {timeSlotIdToDelete && (
        <Modal
          customFooter={() => null}
          isOpen={timeSlotIdToDelete}
          force
          className={css.modal}
          onClose={() => setTimeSlotIdToDelete(null)}>
          <RemoveRecurringTimeSlot
            onSubmit={() => {
              handleRemoveTimeSlot(timeSlotIdToDelete);
              setTimeSlotIdToDelete(null);
            }}
          />
        </Modal>
      )}
    </>
  );
};
