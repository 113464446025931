import orderBy from 'lodash/orderBy.js';
import { useState } from 'react';
import moment from 'moment';

import { useChartData } from '_fsd/features/monthly-appointment-summary-chart';

const filterByMonth = (data, filteredDate) =>
  data?.filter((item) => {
    return (
      moment(item.date).month() === moment(filteredDate).month() &&
      moment(item.date).year() === moment(filteredDate).year()
    );
  });
const countByCaregivers = (data, vets) => {
  if (!data || !vets) return [];
  const caregiverCounts = {};
  vets.forEach((vet) => {
    caregiverCounts[vet.user.uid] = {
      vetUser: vet.user,
      vet,
      count: 0,
      caregiver: `${vet.user.firstName} ${vet.user.lastName}`
    };
  });
  data?.forEach((entry) => {
    const vetUser = entry.vetUser;
    if (!caregiverCounts[vetUser.uid]) {
      caregiverCounts[vetUser.uid] = {
        count: 0,
        vetUser
      };
    }
    caregiverCounts[vetUser.uid].count += 1;
  });
  return orderBy(Object.values(caregiverCounts), ['count'], ['desc']);
};

const generateLast12MonthsData = () => {
  const last12Months = [];
  for (let i = 0; i < 12; i++) {
    const value = moment().subtract(i, 'months').format('MMMM YYYY');
    last12Months.push({
      value,
      label: value
    });
  }
  return last12Months;
};

export const useDashboardData = ({ data, vets }) => {
  const [filteredDate, setFilteredDate] = useState(moment().format('MMMM YYYY'));

  const dataSource = orderBy(
    data?.map(({ date, uid, user, timeSlot }) => {
      return {
        uid,
        date,
        caregiver: `${timeSlot.vet.user.firstName} ${timeSlot.vet.user.lastName}`,
        client: `${user.firstName} ${user.lastName}`,
        vetUser: timeSlot.vet.user
      };
    }),
    ['date'],
    ['desc']
  );

  const monthlyData = filterByMonth(dataSource, filteredDate);
  const apptsByCaregiver = countByCaregivers(monthlyData, vets);
  const chartsData = useChartData(dataSource, vets);

  return {
    apptsByCaregiver,
    monthlyData,
    chartsData,
    setFilteredDate,
    filteredDate,
    last12MonthsDropdown: generateLast12MonthsData()
  };
};
