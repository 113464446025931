import React, { useMemo } from 'react';

import css from './VerAdminDashboard.module.css';
import { getLast12MonthsStartAndEndDates } from 'utils/helpers';
import { MonthlyAppointmentSummaryChart } from '_fsd/features/monthly-appointment-summary-chart';
import { AppointmentHistoryTable } from '_fsd/widgets/appointment-history-table';
import { TotalApptBox } from '_fsd/widgets/total-appointments-box';
import { useAppointmentsByVetAdmin } from '_fsd/entities/appointment';
import { useDashboardData } from '../lib';
import { useVets } from '../../../entities/vet';

export const VetAdminDashboard = () => {
  const { startDate, endDate } = useMemo(() => getLast12MonthsStartAndEndDates(), []);
  const { data, loading } = useAppointmentsByVetAdmin({ startDate, endDate });
  const { data: vets, loading: vetLoading } = useVets();

  const {
    monthlyData,
    chartsData,
    apptsByCaregiver,
    setFilteredDate,
    filteredDate,
    last12MonthsDropdown
  } = useDashboardData({
    data,
    vets
  });

  return (
    <div className={css.container}>
      <div className={css.flex}>
        <TotalApptBox data={apptsByCaregiver} length={monthlyData?.length} />
        <AppointmentHistoryTable
          data={monthlyData}
          loading={loading || vetLoading}
          setFilteredDate={setFilteredDate}
          filteredDate={filteredDate}
          last12MonthsDropdown={last12MonthsDropdown}
        />
      </div>
      <MonthlyAppointmentSummaryChart data={chartsData} />
    </div>
  );
};
