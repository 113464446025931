import React, { useState } from 'react';
import Input from 'components/vet-components/vet-web-inputs/input/Input';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';

import { cssSpace, cssText as text } from '_fsd/shared';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-red.svg';
import css from './RemoveRecurringTimeSlot.module.css';

export const RemoveRecurringTimeSlot = ({ onSubmit }) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  return (
    <div>
      <div className={css.warningIcon}>
        <WarningIcon />
      </div>
      <div className={cls(css.confirmationText, text.s16h24w5)}>
        {t('recurring_time_slot.confirm_delete')}
      </div>
      <div className={cls(css.deleteInfo, text.s14h22w4)}>
        {t('recurring_time_slot.delete_info')}
      </div>
      <div className={css.confirmDeleteContainer}>
        <div className={cls(css.confirmDeleteInputText, text.s14h22w4)}>
          {t('recurring_time_slot.confirm_delete_input')}
        </div>
        <Input
          className={css.input}
          required
          defaultValue={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button
          className={cls(
            css.deleteButton,
            inputValue === 'DELETE' ? css.deleteButtonEnabled : undefined
          )}
          disabled={inputValue !== 'DELETE'}
          onClick={onSubmit}
          type="default">
          {t('recurring_time_slot.delete_time_slot')}
        </Button>
      </div>
    </div>
  );
};
