import { RRule } from 'rrule';

export function filterAndConvertRuleToText(rule) {
  // Split the rule into lines
  const lines = rule.split('\n');
  // Filter out lines starting with "EXDATE:"
  const filteredLines = lines.filter((line) => !line.startsWith('EXDATE:'));
  // Join the filtered lines back into a single string
  const filteredRule = filteredLines.join('\n');
  // Convert the filtered rule string to text
  const ruleText = RRule.fromString(filteredRule).toText();
  return ruleText.charAt(0).toUpperCase() + ruleText.slice(1); // Capitalize the first letter
}
