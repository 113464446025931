export { spreadTimeslot } from './spreadTimeslot';
export { serializeTimeslotUpdateInput, serializeTimeslotEntity } from './serializeTimeslot';
import moment from 'moment';
import dayjs from 'dayjs';

export const processDate = (date) => {
  if (date instanceof dayjs) {
    return date.format('YYYY-MM-DD');
  }
  if (date instanceof moment) {
    return date.format('YYYY-MM-DD');
  }
  if (date instanceof Date) {
    return moment(date).format('YYYY-MM-DD');
  }
  if (typeof date === 'string') {
    return date.slice(0, 10);
  }
};
