import React, { memo, useState } from 'react';
import { Form } from 'antd';
import EditClientAddressModal from './edit-client-address-modal/EditClientAddressModal';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import ModalContent from './components/ModalContent';
import ModalHeader from './components/ModalHeader';
import ModalFooter from './components/ModalFooter';
import useCalendarSteps from '../useCalendarSteps';
import useNewAppointmentModal from '../useNewAppointmentModal';
import { PropertyType } from 'constants/enums';

import './style.css';
import css from './Modal.module.css';
import PetSection from 'components/_new/PetSection/PetSection';
import { RescheduleWrapper, useFilters, useSelectedTimeslot } from '_fsd/widgets';
import { useMediaQuery } from 'react-responsive';
import {
  processAddress,
  serializeSignUpFlow
} from '../../../../../../pages/vet/new-appointment-modal/serializers';

import { Modal } from '_fsd/shared';
import moment from 'moment';
import { AddressNewModal } from '_fsd/features/edit-pet-owner-address-modal';

const NewAppointmentModal = ({
  vet,
  allVets,
  currentStep,
  setCurrentStep,
  changeStep,
  onRequestClose,
  openNewClientModal,
  getAppointmentsByTimeslotUid,
  setCalendarDate
}) => {
  const { isMobile } = mediaQueriesList();
  const [form] = Form.useForm();
  const [selectedClient, setSelectedClient] = useState(null);
  const { addressError, address, setAddress, selectedTimeslot, setSelectedTimeslot, propertyType } =
    useCalendarSteps(vet, selectedClient, true, null, null, form, true);

  const {
    search,
    setSearch,
    clients,
    loading,
    refetchClients,
    clientToEdit,
    setClientToEdit,
    petList,
    setPetList,
    onClientSelect,
    onClientEdit,
    isNextButtonDisabled,
    onNextClick,
    handleSignUp,
    fetchPetOwners,
    total,
    isSubmitting,
    petsToCreate,
    setPetsToCreate,
    onAddressSubmit
  } = useNewAppointmentModal(
    currentStep,
    setCurrentStep,
    3,
    onRequestClose,
    setAddress,
    addressError,
    selectedTimeslot,
    selectedClient,
    setSelectedClient,
    propertyType === PropertyType.PrivateHome,
    form,
    getAppointmentsByTimeslotUid
  );

  const filters = useFilters({
    displayTSNotInAreaDef: true,
    displayVetsDef: true,
    displayFullTSDef: true,
    selectedVetsDef: allVets.map((v) => v.uid)
  });
  const timeslotActions = useSelectedTimeslot({ handleTimeslot: setSelectedTimeslot });

  const steps = [
    {
      content: (
        <ModalContent
          total={total}
          search={search}
          clients={clients}
          loading={loading}
          onClientSelect={onClientSelect}
          fetchPetOwners={fetchPetOwners}
          selectedClient={selectedClient}
          onClientEdit={onClientEdit}
        />
      )
    },
    {
      content: (
        <RescheduleWrapper
          isVetAdmin
          defaultReschedule={false}
          theme="light"
          filters={filters}
          timeslotActions={timeslotActions}
          currentAddress={processAddress(selectedClient?.user?.address)}
          vetId={vet?.uid}
          displayFooter={false}
          displayHeader={false}
          close={() => {}}
        />
      )
    },
    {
      content: (
        <Form
          form={form}
          layout="vertical"
          disabled={isSubmitting}
          initialValues={{
            pets: [],
            isInformClient: true
          }}
          onFinish={(values) => {
            handleSignUp(
              serializeSignUpFlow(
                values,
                selectedClient?.user,
                petList,
                selectedTimeslot?.date,
                selectedTimeslot?.vet,
                selectedTimeslot,
                petsToCreate
              )
            ).then(() => {
              setCalendarDate(moment(selectedTimeslot?.date));
            });
          }}>
          <PetSection
            setPetsToCreate={setPetsToCreate}
            client={selectedClient?.user}
            petList={petList}
            setPetList={setPetList}
            vet={selectedTimeslot?.vet} // TODO: get vet from selected vet dropdown
            isVet
          />
          <Form.Item name="isInformClient" noStyle></Form.Item>
        </Form>
      )
    }
  ];

  return (
    <>
      <Modal
        isOpen={true}
        onRequestClose={onRequestClose}
        onClose={onRequestClose}
        customHeader={
          <ModalHeader
            className={css.modalHeader}
            search={search}
            setSearch={setSearch}
            current={currentStep}
            changeStep={changeStep}
            openNewClientModal={openNewClientModal}
            onRequestClose={onRequestClose}
          />
        }
        customFooter={
          <ModalFooter
            className={css.modalFooter}
            isSubmitting={isSubmitting}
            onRequestClose={onRequestClose}
            isNextButtonDisabled={isNextButtonDisabled}
            onNextClick={onNextClick}
            changeStep={changeStep}
            current={currentStep}
            steps={steps}
            form={form}
          />
        }>
        <div className={css.modalContent}>{steps[currentStep].content}</div>
      </Modal>
      {clientToEdit && clientToEdit.address ? (
        <EditClientAddressModal
          onRequestClose={() => setClientToEdit(null)}
          client={clientToEdit}
          onAddressSubmit={onAddressSubmit}
        />
      ) : (
        <AddressNewModal
          isForVet={true}
          client={clientToEdit}
          isOpen={clientToEdit}
          onClose={() => setClientToEdit(null)}
          onAddressSubmit={onAddressSubmit}
        />
      )}
    </>
  );
};

export default memo(NewAppointmentModal);
