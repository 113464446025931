import React, { useState, useCallback } from 'react';
import { Modal } from '_fsd/shared';
import css from './AddressNew.module.css';
import { AddressNew } from '_fsd/features/edit-pet-owner-address-modal';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import { serializeAddressDescription } from 'serializers/signUpFlow.serializer';

import { PropertyType } from 'utils/enums';

export const AddressNewModal = ({ client, isForVet, isOpen, onClose, onAddressSubmit }) => {
  const { t } = useTranslation();

  const [propertyType, setPropertyType] = useState(PropertyType.PrivateHome);
  const [address, setAddress] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({});

  const onSelect = useCallback((value) => {
    const description = serializeAddressDescription(value);
    setAddress({
      ...value,
      description
    });
  }, []);

  const onChange = useCallback((value) => {
    if (value.length <= 150) {
      setAddress({
        description: value
      });
    }
  }, []);

  const [form] = Form.useForm();

  const initialValues = {
    address: client?.address?.description,
    floor: client?.address?.floor ? String(client?.address?.floor) : '0',
    apartment: client?.address?.apartment,
    userComment: client?.address?.comment
  };

  return (
    <Modal
      title={
        <span>
          {t('new_appointment.add_address')}: {client?.firstName} {client?.lastName}
        </span>
      }
      isOpen={isOpen}
      onClose={() => onClose()}
      initialValues={initialValues}
      onConfirm={() => {
        setSubmitting(true);
        onAddressSubmit(client, values, address, propertyType, onClose);
        setSubmitting(false);
      }}>
      <div className={css.container}>
        <Form
          form={form}
          layout="vertical"
          disabled={isSubmitting}
          onFinish={(values) => {
            setValues(values);
          }}>
          <AddressNew
            address={address}
            isForVet={isForVet}
            onSelect={onSelect}
            setPropertyType={setPropertyType}
            propertyType={propertyType}
            onChange={onChange}
          />
        </Form>
      </div>
    </Modal>
  );
};
