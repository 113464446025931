import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './_fsd/app/translations';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en',
    ns: [
      'common',
      'breadcrumb',
      'timeslot_modal',
      'timeslot_list',
      'appt_details',
      'vet_multiselect',
      'reschedule'
    ],
    defaultNS: 'common',
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
