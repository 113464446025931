import React, { useMemo } from 'react';
import css from './SelectClinicMultipleVet.module.css';
import { Checkbox, getVetName } from '_fsd/shared';
import { MedicineBoxOutlined } from '@ant-design/icons';
import { User } from '_fsd/entities/user';
import { getSelectedLabel } from '../lib';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const Option = ({ vet, selected, indeterminate, onClick, label }) => (
  <div className={css.vetCheckbox} onClick={onClick}>
    <Checkbox checked={selected} indeterminate={indeterminate} />
    {label ? <span>{label}</span> : <User user={vet?.user} />}
  </div>
);

const Selected = ({ text }) => {
  return (
    <div className={css.box}>
      <MedicineBoxOutlined />
      <span>{text}</span>
    </div>
  );
};

export const SelectClinicMultipleVet = ({ setSelectedVets, selectedVets, vets }) => {
  const { t } = useTranslation('vet_multiselect');
  const options = useMemo(() => {
    const optionsArray = (vets || []).map((v) => {
      const selected = selectedVets.some((s) => s.uid === v.uid);
      const onClick = (e) => {
        e.stopPropagation();
        if (selected) {
          setSelectedVets(selectedVets.filter((s) => s.uid !== v.uid));
        } else {
          setSelectedVets([...selectedVets, v]);
        }
      };
      return {
        value: v.uid,
        name: getVetName(v),
        label: <Option key={v.uid} vet={v} selected={selected} onClick={onClick} />
      };
    });
    const allSelected = selectedVets.length === vets.length;
    const indeterminate = selectedVets.length > 0 && !allSelected;

    optionsArray.unshift({
      value: 'all',
      name: 'all',
      label: (
        <Option
          key="all"
          indeterminate={indeterminate}
          selected={allSelected}
          label={t('all_providers_option')}
          onClick={(e) => {
            e.stopPropagation();
            if (allSelected) {
              setSelectedVets([]);
            } else {
              setSelectedVets([...vets]);
            }
          }}
        />
      )
    });
    return optionsArray;
  }, [vets, selectedVets]);
  const text = getSelectedLabel(selectedVets, vets, t);
  const selected = { value: 1, label: <Selected text={text} /> };
  return (
    <div className={css.container}>
      <Select
        className={css.select}
        value={selected}
        options={options}
        onSelect={() => {}}
        showSearch
        optionFilterProp={'name'}
      />
    </div>
  );
};
