import { getVetsAdminQuery } from 'gql';
import { useQuery, useLazyQuery } from '@apollo/client';

export const useClinicVets = ({ order, search, skip } = { search: '' }) => {
  const { data, ...rest } = useQuery(getVetsAdminQuery, {
    variables: {
      search,
      order
    },
    skip
  });
  return { data: data?.getVets, ...rest };
};

export const useClinicVetsLazy = ({ order, search, skip } = { search: '' }) => {
  const [getVets, { data, ...rest }] = useLazyQuery(getVetsAdminQuery, {
    variables: {
      search,
      order
    },
    skip
  });
  return { getVets, data: data?.getVets, ...rest };
};
