import React from 'react';
import { Modal } from '_fsd/shared';
import css from './ReassignVetModal.module.css';
import { message } from 'antd';
import * as Yup from 'yup';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { useVets } from '../../../../entities/vet';
import LoaderWrapper from '../../../../../components/loaderWrapper/LoaderWrapper';
import { User } from '_fsd/entities/user';
import {
  serializeTimeslotUpdateInput,
  useCreateTimeslotFromRecurring,
  useReassignVetForTimeslot
} from '../../../../entities/timeslot';
import { useFormik } from 'formik';
import { processDate } from '../../../../../pages/vet/new-appointment-modal/serializers';

export const ReassignVetModal = ({ open, onClose, timeslotId, timeslot, excludeVetId }) => {
  const { t } = useTranslation('timeslot_list');
  const [reassignMutation] = useReassignVetForTimeslot({
    refetchQueries: [
      'getAuthVet',
      'getFilteredTimeSlotsByVetUid',
      'getTimeSlots',
      'getAppointmentsByDate'
    ]
  });
  const [editTimeslotFromRecurring] = useCreateTimeslotFromRecurring({
    refetchQueries: [
      'getAuthVet',
      'getFilteredTimeSlotsByVetUid',
      'getTimeSlots',
      'getAppointmentsByDate'
    ]
  });
  const formik = useFormik({
    initialValues: {
      vetId: undefined
    },
    validationSchema: Yup.object({
      vetId: Yup.string().required()
    }),
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: ({ vetId }, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      let action;
      if (timeslot.isRecurring) {
        const variables = serializeTimeslotUpdateInput(timeslot);
        variables.date = processDate(variables.date);
        variables.isRecurring = false;
        variables.workingAreas = variables.workingAreas.map((wa) => wa.uid);
        variables.type = 'work_availability';
        variables.oldDate = processDate(timeslot.date);
        variables.vetId = undefined;
        action = editTimeslotFromRecurring({
          variables: {
            record: {
              timeSlot: variables,
              vetId
            }
          }
        });
      } else {
        action = reassignMutation({
          variables: {
            record: {
              timeslotId,
              vetId
            }
          }
        });
      }
      action
        .then(() => {
          message.success(t('reassignVetModal.success_status'));
          onClose();
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    }
  });
  const { data, loading } = useVets();

  return (
    <Modal
      className={css.modal}
      classNameContent={css.modalContent}
      isOpen={open}
      title={t('reassignVetModal.title')}
      onClose={onClose}
      footerProps={{
        displayCancel: false,
        titleConfirm: t('reassignVetModal.button'),
        buttonConfirmProps: {
          className: css.button,
          disabled: loading || !!formik.errors.vetId || formik.isSubmitting
        }
      }}
      onConfirm={formik.submitForm}>
      <LoaderWrapper
        isLoading={loading || formik.isSubmitting}
        style={{ height: '100%', minHeight: 100 }}>
        {data
          ?.filter((v) => v.uid !== excludeVetId)
          ?.map((vet) => (
            <div
              onClick={() => formik.setFieldValue('vetId', vet.uid)}
              key={vet.uid}
              className={cls(css.selectable, { [css.active]: formik.values.vetId === vet.uid })}>
              <User user={vet.user} size="lg" />
            </div>
          ))}
      </LoaderWrapper>
    </Modal>
  );
};
