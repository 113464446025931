import React from 'react';
import { Table, Select } from 'antd';
import { useColumns, useAppointmentsTable } from '../lib';
import css from './AppointmentHistoryTable.module.css';
import { useTranslation } from 'react-i18next';

export const AppointmentHistoryTable = ({
  data = [],
  loading,
  setFilteredDate,
  filteredDate,
  last12MonthsDropdown
}) => {
  const { t } = useTranslation();
  const columns = useColumns();

  return (
    <div className={css.list}>
      <div className={css.header}>
        <span className={css.titleText}>{t('admin_dashboard.list_appointments')}</span>
        <span>
          <span className={css.filterByText}>{t('admin_dashboard.filter_by')}</span>
          <Select
            className={css.datePicker}
            defaultValue={filteredDate}
            value={filteredDate}
            options={last12MonthsDropdown}
            onChange={(d) => setFilteredDate(d)}
          />
        </span>
      </div>
      <div className={css.container}>
        <Table
          locale={{ emptyText: t('empty', { ns: 'stats' }) }}
          dataSource={data}
          columns={columns}
          pagination={false}
          loading={loading}
        />
      </div>
    </div>
  );
};
