import React, { useMemo } from 'react';
import cn from 'classnames';
import { Input, Select, Row, Tooltip, Collapse, Popover } from 'antd';
import {
  HolderOutlined,
  CarOutlined,
  PlusOutlined,
  FilePdfOutlined,
  FileOutlined,
  EditOutlined,
  InfoCircleFilled,
  MoreOutlined,
  DeleteOutlined,
  EyeOutlined,
  RollbackOutlined,
  ExclamationCircleFilled
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useGetUserConcernsByVetUid } from '_fsd/entities/vet';
import {
  Col,
  Tag,
  cssText,
  cssColor,
  cssSpace,
  Label,
  Button,
  useOutsideClick,
  useUpdateAppointmentPet,
  AttachmentIcon
} from '_fsd/shared';
import {
  IntegrationErrorPopover,
  PetOwnerShortRow,
  PetRow,
  isEntityIntegrationError,
  isAppointmentIntegrationError
} from '_fsd/entities';
import { getOptions, getTimeSlotTime } from 'utils/helpers';
import css from './AppointmentRow.module.css';
import last from 'lodash/last';
import { useQuery } from '@apollo/client';
import { getUserConcernsByUserIdQuery } from 'gql';
import AppointmentPopover from '../../../../../pages/vet/routes/appointments/AppointmentPopover';
import { routeStatus as Route } from '../../../../../components/timeline/appointments/useTimelineAppointments';

// feature
export const ETAView = ({ data }) => {
  const { t } = useTranslation();
  const { etaStart, etaEnd, originalStartTime, originalEndTime, route: { status } = {} } = data;
  const isEtaExists = !!data?.etaStart && !!data?.etaEnd;
  const text = !isEtaExists
    ? status?.toLowerCase() === Route.REGISTER
      ? t('vet-appointments.ETARouteRegister')
      : t('vet-appointments.noCommittedETAHelp')
    : t('vet-appointments.committedETAHelp');
  return (
    <Col>
      <Row align="middle" className={css.etaTitle}>
        {!etaStart && status?.toLowerCase() !== Route.REGISTER && (
          <span className={cn(cssText.s14h16w4, cssColor.cBlackO40)}>
            {t('vet-appointments.noCommittedETALabel')}
          </span>
        )}
        {!etaStart && status?.toLowerCase() === Route.REGISTER && (
          <span className={cn(cssText.s14h16w4, cssColor.cBlackO40)}>
            {t('vet-appointments.ETALabelRegister')}
          </span>
        )}
        {etaStart && etaEnd && (
          <span className={cn(cssColor.cBlue, cssText.s14h22w5)}>
            {getTimeSlotTime(etaStart)} - {getTimeSlotTime(etaEnd)}
          </span>
        )}
        <Tooltip title={text}>
          <InfoCircleFilled className={css.info} />
        </Tooltip>
      </Row>
      {originalStartTime && originalEndTime && (
        <span
          className={cn(cssColor.cBlackO40, cssText.s12h16w4, cssSpace.mt5, css.originalScheduled)}>
          <span>{t('vet-appointments.originalScheduleLabel')}</span>
          <span>
            {` ${getTimeSlotTime(originalStartTime)} - ${getTimeSlotTime(originalEndTime)}`}
          </span>
        </span>
      )}
    </Col>
  );
};
// feature
const Menu = ({ data, setSelectedAppointment, routeStatus }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className={css.more}>
      <Popover
        content={
          <AppointmentPopover
            handleTogglePopover={() => setOpen(false)}
            setSelectedAppointment={setSelectedAppointment}
            uid={data.uid}
            appointment={data}
            user={data.user}
            routeStatus={routeStatus}
            pets={data.pets}
          />
        }
        onVisibleChange={setOpen}
        visible={open}
        title="Title"
        trigger="click">
        <div className={css.menuMoreIcon}>
          <MoreOutlined onClick={() => setOpen(true)} />
        </div>
      </Popover>
    </div>
  );
};
// feature
const Brief = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Row align="middle" className={cssSpace.gap8}>
      {data.files?.length > 0 && <AttachmentIcon />}
      {data.isFirstVisit && <Tag>{t('vet-appointments.firstVisitLabel')}</Tag>}
    </Row>
  );
};
// feature
const Concerns = ({ data, onRemove, style = { flex: 2 }, short = true, editable = false }) => {
  const limit = 3;
  const { length, concerns } = React.useMemo(() => {
    if (short) {
      return {
        length: data.userConcerns?.length,
        concerns: data.userConcerns?.slice(0, limit)
      };
    } else {
      return {
        length: 0,
        concerns: data.userConcerns
      };
    }
  }, [data.userConcerns, short]);
  const [open, setOpen] = React.useState(false);

  return (
    <Row align="middle" className={cssSpace.gap8} style={style}>
      {concerns?.map((c) => (
        <Popover
          key={c.uid}
          trigger="click"
          open={editable ? open === c.uid : false}
          onOpenChange={() => setOpen(open === c.uid ? false : c.uid)}
          content={
            <Col onClick={() => setOpen(false)}>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => onRemove(c.uid)}
                className={cn(cssSpace.w100, cssSpace.mt4)}>
                Remove
              </Button>
            </Col>
          }>
          <Tag gray editable={editable} className={cn({ [css.tagEditable]: editable })}>
            {c.itemValue || c?.concern?.name}
          </Tag>
        </Popover>
      ))}
      {length > limit && <Tag gray>+{length - limit}</Tag>}
    </Row>
  );
};
// entity
const VisitDuration = ({ setSelectedAppointment, data, duration, viewMode }) => {
  const { t } = useTranslation();
  const onClick = () => {
    !viewMode &&
      setSelectedAppointment((prev) => ({
        ...prev,
        duration: data
      }));
  };
  return (
    <Row align="middle" className={cssSpace.gap8}>
      <span className={cssText.s12h24w4}>{`${t('vet-appointments.visitDurationLabel')}`}</span>
      <span onClick={onClick} className={cn(cssText.s12h16w5, css.duration)}>
        {`${duration} min`}
      </span>
    </Row>
  );
};
// entity
const File = ({ file, onRemove }) => {
  const fileName = last(file.split('/'));
  const v4 = new RegExp(
    /(_[0-9(a-f|A-F)]{8}-[0-9(a-f|A-F)]{4}-4[0-9(a-f|A-F)]{3}-[89ab][0-9(a-f|A-F)]{3}-[0-9(a-f|A-F)]{12})/
  );
  const name = fileName.replace(v4, '');
  const format = last(fileName.split('.'));
  const [open, setOpen] = React.useState(false);

  return (
    <div className={css.file}>
      <Popover
        trigger="click"
        open={open}
        onOpenChange={setOpen}
        content={
          <Col onClick={() => setOpen(false)}>
            <Button
              icon={<EyeOutlined />}
              className={cn(cssSpace.w100, cssSpace.mt4)}
              onClick={() => window.open(file, '_blank')}>
              View
            </Button>
            <Button
              icon={<DeleteOutlined />}
              onClick={onRemove}
              className={cn(cssSpace.w100, cssSpace.mt4)}>
              Remove
            </Button>
            <Button icon={<RollbackOutlined />} className={cn(cssSpace.w100, cssSpace.mt4)}>
              Cancel
            </Button>
          </Col>
        }>
        <Row align="middle" className={css.fileRow}>
          <div className={css.fileIcon}>
            {format.toLowerCase() === 'pdf' ? <FilePdfOutlined /> : <FileOutlined />}
          </div>
          <Col className={cssSpace.ml8}>
            <span className={cn(cssText.s12h18w5)}>{name}</span>
            <span className={cn(cssText.s11h18w4, cssColor.c8A94A6)}>{format.toLowerCase()}</span>
          </Col>
        </Row>
      </Popover>
    </div>
  );
};
// feature
const Files = ({ data, onRemove }) => {
  const removeFile = React.useCallback(
    (file) => {
      const newFiles = data.files.filter((f) => f !== file).join(',');
      onRemove(newFiles);
    },
    [data.files, onRemove]
  );
  return (
    <div className={css.files}>
      {data.files?.map((f) => (
        <File onRemove={() => removeFile(f)} key={f} file={f} />
      ))}
    </div>
  );
};

// feature
const Editable = ({ value, onSave, label, viewMode }) => {
  const { t } = useTranslation();
  const [inputValue, setValue] = React.useState(value);
  const [editable, setEditable] = React.useState(false);
  const ref = React.useRef();
  const inputRef = React.useRef();
  useOutsideClick(ref, () => setEditable(false));
  React.useEffect(() => {
    if (editable) {
      inputRef.current.focus();
      const v = inputRef.current.resizableTextArea.textArea.value;
      inputRef.current.resizableTextArea.textArea.value = '';
      inputRef.current.resizableTextArea.textArea.value = v;
    }
  }, [editable]);

  return (
    <Col
      ref={ref}
      className={cn(cssSpace.w100, cssSpace.p8, {
        [css.editableContainer]: editable
      })}
      onClick={() => !viewMode && setEditable(true)}>
      <Label className={cssText.s12h16w5}>
        {label}
        {!editable && !viewMode && value && <EditOutlined className={cssSpace.ml8} />}
        {!editable && !viewMode && !value && <PlusOutlined className={cssSpace.ml8} />}
      </Label>
      {!editable && (
        <Row className={cn(cssSpace.gap8, cssSpace.mt8)}>
          {value && <span className={cn(cssText.s14h22w4, css.commentTextArea)}>{value}</span>}
        </Row>
      )}
      {editable && (
        <div className={css.editable}>
          <Input.TextArea
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setValue(e.target.value)}
            className={css.textarea}
            placeholder={t('vet-appointments.placeholderLabel')}
          />
          <Row justify="end">
            <Button
              size="small"
              onClick={(ev) => {
                ev.stopPropagation();
                onSave(inputValue);
                setEditable(false);
              }}>
              {t('vet-appointments.saveBtnLabel')}
            </Button>
          </Row>
        </div>
      )}
    </Col>
  );
};

// feature
const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
const ConcernEdit = ({ vet, onAdd, selected }) => {
  const { data: concernsRaw = [] } = useGetUserConcernsByVetUid(vet?.uid, true);

  const ref = React.useRef();
  React.useEffect(() => {
    ref?.current?.focus();
  }, []);
  const ids = React.useMemo(() => selected.map((opt) => opt.uid), [selected]);
  const concerns = useMemo(() => {
    return getOptions(concernsRaw, {
      value: 'uid',
      label: (v) => v.itemValue || v.concern?.name
    }).filter((opt) => !ids.includes(opt.value));
  }, [concernsRaw, ids]);
  return (
    <Select
      ref={ref}
      size="small"
      placeholder="Type reason"
      className={css.selectConcern}
      options={concerns}
      onChange={(v) => onAdd([...ids, v])}
      filterOption={filterOption}
      isSearchable
      showSearch
    />
  );
};

// widget
const AppointmentDetails = ({ data, vet, viewMode }) => {
  const [concernEdit, setConcernEdit] = React.useState(false);
  const ref = React.useRef();
  const { t } = useTranslation();
  const [update] = useUpdateAppointmentPet();

  return (
    <Col className={cn(cssSpace.w100, cssSpace.pl32)}>
      <Col ref={ref} className={cn(cssSpace.w100, cssSpace.p8)}>
        <Label className={cssText.s12h16w5}>{t('vet-appointments.servicesLabel')}</Label>
        <Row className={cn(cssSpace.gap8, cssSpace.mt8)}>
          <Concerns
            data={data}
            short={false}
            style={{}}
            editable={!viewMode}
            onRemove={(uid) =>
              update({
                variables: {
                  record: {
                    appointmentPetId: data.uid,
                    userConcerns: data.userConcerns.map((uc) => uc.uid).filter((u) => u !== uid)
                  }
                }
              })
            }
          />
          {!concernEdit && !viewMode && (
            <Button size="small" className={css.addButton} onClick={() => setConcernEdit(true)}>
              {t('vet-appointments.addBtnLabel')}
            </Button>
          )}
          {concernEdit && (
            <ConcernEdit
              vet={vet}
              selected={data.userConcerns}
              onAdd={(value) => {
                update({
                  variables: {
                    record: {
                      appointmentPetId: data.uid,
                      userConcerns: value
                    }
                  }
                });
                setConcernEdit(false);
              }}
            />
          )}
        </Row>
      </Col>
      <Editable
        viewMode={viewMode}
        value={data.comment}
        label={t('vet-appointments.userCommentLabel')}
        onSave={(v) =>
          update({
            variables: {
              record: {
                appointmentPetId: data.uid,
                comment: v
              }
            }
          })
        }
      />
      <Editable
        viewMode={viewMode}
        value={data.privateComment}
        onSave={(v) =>
          update({
            variables: {
              record: {
                appointmentPetId: data.uid,
                privateComment: v
              }
            }
          })
        }
        label={t('vet-appointments.privateCommentLabel')}
      />
      {data?.files?.length ? (
        <>
          <div className={css.hr} />
          <Files
            data={data}
            onRemove={(files) =>
              update({
                variables: {
                  record: {
                    appointmentPetId: data.uid,
                    fileUrls: files
                  }
                }
              })
            }
          />
        </>
      ) : null}
    </Col>
  );
};

export const AppointmentRow = ({
  defaultOpen,
  data,
  viewMode,
  isDragging,
  vet,
  hideHr,
  setSelectedAppointment,
  routeStatus,
  onClickAddress,
  vetAdmin
}) => {
  const { t } = useTranslation();
  const [expand, setExpand] = React.useState([]);
  const {
    appointmentRoute: { travelingTime, address, duration, uid, route },
    pets = [],
    user,
    appointmentIntegrations
  } = data;

  const showIntegrationErrors = isAppointmentIntegrationError({
    route,
    vetAdmin,
    appointmentIntegrations
  });

  const integrationsWithError = appointmentIntegrations?.filter((i) => isEntityIntegrationError(i));

  return (
    <>
      {!hideHr && !isDragging && !viewMode && (
        <Row justify="center" align="middle" className={css.mt16}>
          <div className={css.line} />
          {typeof travelingTime === 'number' && (
            <span className={cn(css.travelingTime, cssColor.cBlue, cssText.p2)}>
              <CarOutlined />
              {` ${travelingTime} min`}
            </span>
          )}
          <div className={css.line} />
        </Row>
      )}
      <Col className={cn(css.container, css.mt16)}>
        <Row justify="space-between" className={css.header}>
          <Row align="middle">
            {isDragging && <HolderOutlined className={css.dragging} />}
            <ETAView data={data.appointmentRoute} />
          </Row>
          {showIntegrationErrors && (
            <IntegrationErrorPopover
              content={
                integrationsWithError
                  .map((ai) => {
                    return typeof ai.errorDetails.message === 'object'
                      ? Object.values(ai.errorDetails.message)
                          .map(({ message }) => message)
                          .join('\n')
                      : ai.errorDetails.message;
                  })
                  .join('\n') || t('integration.error_popover.appointment_error_message')
              }>
              <ExclamationCircleFilled className={css.warningIcon} />
            </IntegrationErrorPopover>
          )}
          {!viewMode && (
            <Menu
              data={data}
              setSelectedAppointment={setSelectedAppointment}
              routeStatus={routeStatus}
            />
          )}
        </Row>
        <Row justify="space-between" align="middle" className={cssSpace.w100}>
          <PetOwnerShortRow
            user={user}
            onClickAddress={() => onClickAddress(data)}
            viewMode={viewMode}
            name={`${user.firstName}` + (user.lastName ? ` ${user.lastName}` : '')}
            address={address.description}
          />
          <VisitDuration
            setSelectedAppointment={setSelectedAppointment}
            data={data}
            duration={duration}
            uid={uid}
            viewMode={viewMode}
          />
        </Row>
        <Col className={cssSpace.w100}>
          <Collapse
            defaultActiveKey={defaultOpen ? pets.map((c) => c.uid) : undefined}
            expandIconPosition="end"
            ghost
            className={css.collapse}
            onChange={setExpand}>
            {pets.map((p) => (
              <Collapse.Panel
                showArrow={false}
                disabled={viewMode}
                className={css.panel}
                key={p.uid}
                header={
                  <Row justify="space-between" align="middle" className={css.petRowPanel}>
                    <Row align="middle" style={{ flex: 1 }} className={css.petRowPanelPet}>
                      <PetRow pet={p.pet} />
                      {!expand.includes(p.uid) && <Concerns data={p} />}
                    </Row>
                    <Row justify="end">
                      <Brief data={p} />
                    </Row>
                  </Row>
                }>
                <AppointmentDetails data={p} vet={vet} />
              </Collapse.Panel>
            ))}
          </Collapse>
        </Col>
      </Col>
    </>
  );
};
