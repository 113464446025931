import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import { SubscriptionStatus, SubscriptionTypeName } from 'utils/enums';

import './customers-styles.css';
import { UserContactInfoPopover } from '../../../_fsd/features/user-contact-info-popover';

export const useCustomersColumns = (setCustomerData) => {
  const { t } = useTranslation();

  const handlePetStatus = (subscription) => {
    if (subscription?.subscriptionType?.name) {
      // if subscription is monthly return subscription status
      if (subscription?.subscriptionType?.name === SubscriptionTypeName.MONTHLY) {
        return subscription?.status;
      } else {
        // if pet has active one-time return ACTIVE status
        // if not return empty
        return subscription?.status === SubscriptionStatus.ACTIVE ? subscription?.status : '';
      }
    }
  };

  return [
    {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      key: 'registrationDate'
    },
    {
      title: t('customers.client.name'),
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: t('customers.contact.info'),
      dataIndex: 'contacts',
      align: 'center',
      key: 'contacts',
      className: 'contacts-column',
      render: (text, record) => (
        <UserContactInfoPopover
          onOpenEdit={() => setCustomerData(record)}
          phone={record.phone}
          email={record.email}
          city={record.city}
          street={record.street}>
          @
        </UserContactInfoPopover>
      )
    },
    {
      title: t('customers.pet.amount'),
      dataIndex: 'petAmount',
      align: 'center',
      key: 'petAmount',
      render: (text, record) => (
        <Popover
          content={
            <div className="popover">
              {!!record?.pets?.length &&
                record?.pets.map((pet) => {
                  return (
                    <div className="single-popover" key={pet.uid}>
                      <div className="popover-address">{t('name')}:</div>
                      {pet.name}
                      {pet?.activeSubscription && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <div className="popover-address">{t('status')}:</div>
                          {handlePetStatus(pet?.activeSubscription)}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          }>
          <div style={{ cursor: 'pointer' }}>{record.petAmount}</div>
        </Popover>
      )
    },

    {
      title: t('customers.next.appointment.date'),
      dataIndex: 'nextAppointmentDate',
      align: 'center',
      key: 'nextAppointmentDate',
      render: (text, record) => {
        let dateString;

        if (record?.nextAppointmentDate?.isValid()) {
          dateString = record.nextAppointmentDate.format(t('date-time-format.full-date'));
        } else if (record?.nextAppointmentEstimation?.isValid()) {
          dateString =
            t('customers.next.estimated-short') +
            ' ' +
            record.nextAppointmentEstimation.format(t('date-time-format.full-date'));
        } else {
          dateString = t('customers.next.no-appointments');
        }

        return <div>{dateString}</div>;
      }
    },

    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => (
        <div className="action-buttons-wrapper">
          <div>
            <EditOutlined
              className="action-button"
              onClick={() => {
                setCustomerData(record);
              }}
            />
          </div>
        </div>
      )
    }
  ];
};

export default useCustomersColumns;
