import React, { useState, useMemo, useEffect } from 'react';
import cls from 'classnames';
import css from './ClinicVetsMap.module.css';
import { Checkbox } from '_fsd/shared';
import { User } from '_fsd/entities/user';
import { AdminMapVets, getColor } from '_fsd/features/admin-map-vets';
import { getAppointmentsByDateMapQuery } from 'gql';
import { useQuery } from '@apollo/client';

export const ClinicVetsMap = ({ vetsMap, appsFilters }) => {
  const { data, loading } = useQuery(getAppointmentsByDateMapQuery, {
    variables: {
      record: {
        vets: vetsMap.map((v) => v.uid),
        date: appsFilters.date,
        timeSlotId: undefined
      }
    }
  });

  const apps = data?.getAppointmentsByDate;
  const vets = useMemo(
    () =>
      vetsMap.map((v, i) => ({
        ...v,
        index: i,
        apps: apps?.filter((a) => a.appointmentRoute?.route?.vet.uid === v.uid) || []
      })),
    [vetsMap, apps]
  );

  const [selectedVets, setSelectedVets] = useState(vets);

  useEffect(() => {
    if (!loading && apps) {
      setSelectedVets(
        selectedVets.map((v) => ({
          ...v,
          apps: apps?.filter((a) => a.appointmentRoute?.route?.vet.uid === v.uid) || []
        }))
      );
    }
  }, [apps, loading]);

  return (
    <div className={css.container}>
      <div className={css.filters}>
        <div
          className={css.vetCheckbox}
          onClick={() => {
            if (selectedVets.length === vets.length) {
              setSelectedVets([]);
            } else {
              setSelectedVets(vets);
            }
          }}>
          <span>All providers</span>
          <Checkbox
            indeterminate={selectedVets.length !== vets.length && selectedVets.length}
            checked={selectedVets.length === vets.length && selectedVets.length}
          />
        </div>
        {vets?.map((vet) => {
          const selected = selectedVets.some((u) => u.uid === vet.uid);
          return (
            <div
              key={vet.uid}
              className={css.vetCheckbox}
              onClick={() => {
                if (selected) {
                  setSelectedVets((prev) => prev.filter((v) => v.uid !== vet.uid));
                } else {
                  setSelectedVets((prev) => [...prev, vet]);
                }
              }}>
              <div className={css.user}>
                <div className={cls(css.colorBox)} style={{ background: getColor(vet.index) }} />
                <User user={vet?.user} />
              </div>
              <Checkbox checked={selected} />
            </div>
          );
        })}
      </div>
      <div className={css.map}>
        <AdminMapVets vets={selectedVets} />
      </div>
    </div>
  );
};
