import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input as AntInput, Tooltip } from 'antd';

import { ReactComponent as Eye } from 'assets/icons/visibility.svg';

import './styles.css';

const Input = ({
  label,
  name,
  placeholder,
  required,
  hasVisibilitySign,
  type,
  onChange,
  whitespace = false,
  className
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      className={className}
      label={
        label || hasVisibilitySign ? (
          <div>
            {label}
            {hasVisibilitySign && (
              <Tooltip title={t('info.will.be.visible')}>
                <Eye className="address-info-icon" />
              </Tooltip>
            )}
          </div>
        ) : null
      }
      name={name}
      rules={[{ required, type, message: t('field.is.required') }, { whitespace }]}>
      <AntInput
        size="middle"
        placeholder={placeholder}
        onChange={onChange ? onChange : () => void 0}
      />
    </Form.Item>
  );
};

export default memo(Input);
