export const computeMonthlyOnThe = (onThe) => {
  const repeat = {
    bysetpos: onThe.which || 1
  };

  switch (onThe.day) {
    case 'Monday':
      repeat.byweekday = [0];
      break;
    case 'Tuesday':
      repeat.byweekday = [1];
      break;
    case 'Wednesday':
      repeat.byweekday = [2];
      break;
    case 'Thursday':
      repeat.byweekday = [3];
      break;
    case 'Friday':
      repeat.byweekday = [4];
      break;
    case 'Saturday':
      repeat.byweekday = [5];
      break;
    case 'Sunday':
      repeat.byweekday = [6];
      break;
    case 'Day':
      repeat.byweekday = [0, 1, 2, 3, 4, 5, 6];
      break;
    case 'Weekday':
      repeat.byweekday = [0, 1, 2, 3, 4];
      break;
    case 'Weekend day':
      repeat.byweekday = [5, 6];
      break;
    default:
      repeat.byweekday = [0];
      break;
  }

  return repeat;
};
