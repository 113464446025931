import React, { useState } from 'react';
import css from './AddressNew.module.css';
import { Button } from '_fsd/shared';
import { AddressInput } from '_fsd/features';
import { useTranslation } from 'react-i18next';
import AdditionalInfo from '_fsd/pages/vet-schedule/ui/outdate/calendar/steps/address/components/AdditionalInfo';

export const AddressNew = (props) => {
  const { t } = useTranslation();
  const { address, addressError, isForVet, onSelect, onChange, propertyType, setPropertyType } =
    props;
  const [showAddressAdditionalInfo, setShowAddressAdditionalInfo] = useState(false);

  return (
    <div className={css.container}>
      <span className={css.address}>Client address</span>
      <AddressInput
        address={address}
        addressError={addressError}
        isForVet={isForVet}
        onSelect={onSelect}
        onChange={onChange}
      />
      <Button
        colorScheme="orange"
        type="link"
        onClick={() =>
          setShowAddressAdditionalInfo((showAddressAdditionalInfo) => !showAddressAdditionalInfo)
        }>
        {t(`admin.recommender.address_details.${showAddressAdditionalInfo ? 'hide' : 'add'}`)}{' '}
        Additional Address Info
      </Button>

      {showAddressAdditionalInfo && (
        <AdditionalInfo
          propertyType={propertyType}
          setPropertyType={setPropertyType}
          isForVet={isForVet}
        />
      )}
    </div>
  );
};
