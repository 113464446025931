import React, { useCallback, useState } from 'react';
import NewAppointmentModal from '../../../../pages/vet/new-appointment-modal/NewAppointmentModal';
import GenerateRouteModal from '_fsd/pages/vet-schedule/ui/outdate/modals/GenerateRouteModal';

export const NewAppModalWrapper = ({
  vet,
  calendarDate,
  selectedTimeslot,
  close,
  isNewAppointmentsModalOpen,
  refetchApps,
  route,
  apps,
  setIsNewClientModalOpen
}) => {
  const [isGenerateRouteModalOpen, setIsGenerateRouteModalOpen] = useState({
    isOpen: false,
    shouldSendEta: false
  });

  const [current, setCurrent] = useState(0);
  const changeStep = useCallback((step) => step < current && setCurrent(step), [current]);
  return (
    <>
      {isNewAppointmentsModalOpen && (
        <NewAppointmentModal
          vet={vet}
          current={current}
          changeStep={changeStep}
          setCurrent={setCurrent}
          calendarDate={calendarDate}
          selectedTimeslot={selectedTimeslot}
          openNewClientModal={() => {
            setIsNewClientModalOpen(true);
            close();
          }}
          onRequestClose={close}
          getAppointmentsByTimeslotUid={() => {
            refetchApps();
          }}
          openGenerateRouteModal={setIsGenerateRouteModalOpen}
          vetTimezoneSlug={selectedTimeslot?.vet?.timezone?.slug}
        />
      )}
      {isGenerateRouteModalOpen.isOpen && (
        <GenerateRouteModal
          routeId={route?.uid ?? apps?.[0]?.appointmentRoute?.route?.uid}
          routeStatus={route?.status ?? apps?.[0]?.appointmentRoute?.route?.status}
          isGenerateRouteModalOpen={isGenerateRouteModalOpen}
          onRequestClose={() =>
            setIsGenerateRouteModalOpen({ isOpen: false, shouldSendEta: false })
          }
        />
      )}
    </>
  );
};
