import React, { useState } from 'react';
import css from './RepeatMonthly.module.css';
import { Select } from 'antd';
import translateLabel from 'common_repo/service/rule-generator/utils/translateLabel';
import translations from 'common_repo/service/rule-generator/translations/english';
import { DAYS } from 'common_repo/service/rule-generator/constants';
import { computeMonthlyOnThe, getValueByWeekDay } from '../utils';

export const RepeatMonthly = ({ onChange, value }) => {
  const [type, setType] = useState(value?.options?.byweekday ? 'rule' : 'day');

  return (
    <div className={css.container}>
      <Select
        className={css.selectType}
        name="repeat.monthly.on.day"
        value={type}
        onChange={(v) => {
          if (v === 'rule') {
            onChange(computeMonthlyOnThe({ which: 1, day: DAYS[0] }));
          }
          setType(v);
        }}>
        <Select.Option value="day">On day</Select.Option>
        <Select.Option value="rule">On the</Select.Option>
      </Select>
      {type === 'day' ? (
        <Select
          className={css.select}
          name="repeat.monthly.on.day"
          value={value?.options?.bymonthday}
          onChange={(value) => onChange({ bymonthday: value })}>
          {[...new Array(31)].map((day, i) => (
            <Select.Option key={i} value={i + 1}>
              {i + 1}
            </Select.Option>
          ))}
        </Select>
      ) : null}
      {type === 'rule' ? (
        <>
          <Select
            className={css.select}
            name="repeat.monthly.onThe.which"
            value={value?.options?.bysetpos}
            onChange={(v) => {
              onChange(computeMonthlyOnThe({ which: v }));
            }}>
            <Select.Option value={1}>
              {translateLabel(translations, 'numerals.first')}
            </Select.Option>
            <Select.Option value={2}>
              {translateLabel(translations, 'numerals.second')}
            </Select.Option>
            <Select.Option value={3}>
              {translateLabel(translations, 'numerals.third')}
            </Select.Option>
            <Select.Option value={4}>
              {translateLabel(translations, 'numerals.fourth')}
            </Select.Option>
            <Select.Option value={-1}>
              {translateLabel(translations, 'numerals.last')}
            </Select.Option>
          </Select>

          <Select
            className={css.select}
            name="repeat.monthly.onThe.day"
            value={getValueByWeekDay(value?.options?.byweekday)}
            onChange={(v) =>
              onChange(computeMonthlyOnThe({ which: value?.options?.bysetpos, day: v }))
            }>
            {DAYS.map((day) => (
              <Select.Option key={day} value={day}>
                {translateLabel(translations, `days.${day.toLowerCase()}`)}
              </Select.Option>
            ))}
          </Select>
        </>
      ) : null}
    </div>
  );
};
