import React from 'react';
import { Skeleton as AntdSkeleton } from 'antd';
import css from './VetApptManagerPage.module.css';
import cls from 'classnames';

export const Skeleton = (props) =>
  props.loading ? (
    <div className={css.skeletonContainer}>
      <div className={css.row}>
        <AntdSkeleton.Node active className={css.item1}>
          <div className={css.empty} />
        </AntdSkeleton.Node>
        <AntdSkeleton.Node active className={css.item2}>
          <div className={css.empty} />
        </AntdSkeleton.Node>
      </div>
      <div className={cls(css.row, css.section2)}>
        <AntdSkeleton.Node active className={css.item3}>
          <div className={css.empty} />
        </AntdSkeleton.Node>
        <AntdSkeleton.Node active className={css.item4}>
          <div className={css.empty} />
        </AntdSkeleton.Node>
      </div>
      <div className={cls(css.section3)}>
        <AntdSkeleton.Node active className={css.item5}>
          <div className={css.empty} />
        </AntdSkeleton.Node>
      </div>
    </div>
  ) : (
    props.children
  );
