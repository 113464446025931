import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import moment from 'moment';
import { Calendar, Col, Form, Row, Typography } from 'antd';

import mediaQueriesList from 'common_repo/utils/mediaQueriesList';

import AddressAutocomplete from 'components/vet-components/address-autocomplete/AddressAutocomplete';
import AdditionalInfo from './AdditionalInfo';
import './style.scss';
import { sortByStartTime } from '../../../../utils/helpers';

const { Text } = Typography;

const AddressStep = ({
  onSelect,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTimeAndVetId,
  calendarIsDisabled,
  form,
  placeId,
  address,
  addressError,
  vet,
  getTime,
  vetIsOccupied,
  auth,
  dates,
  timeSlots,
  propertyType,
  setPropertyType,
  isForVet
}) => {
  const { t } = useTranslation();
  const { isMobile } = mediaQueriesList();

  const defaultDate = selectedDate ? moment(selectedDate) : moment();

  useEffect(() => {
    if (Object.keys(dates)?.length) {
      const key = Object.keys(dates)?.sort()?.[0];
      form.setFieldsValue({
        date: key
      });
      if (dates[key]?.length === 1) setSelectedTimeAndVetId(dates[key][0], dates[key][0]?.vet?.uid);
    }
  }, [dates]);

  return (
    <Row className="address-step">
      {!placeId && (
        <Col
          xs={24}
          sm={24}
          md={isForVet ? 24 : 12}
          lg={isForVet ? 24 : 12}
          xl={isForVet ? 24 : 12}>
          <Form.Item name="address" className="address-field">
            <h3>
              {isForVet
                ? t('scheduling_stepper.vet_address_title')
                : t(auth ? 'check.your.address' : 'enter.your.address') + '*'}
            </h3>
            <AddressAutocomplete
              onSelect={onSelect}
              address={address}
              isForZipCode
              isForCalendar
              error={address && !!Object.keys(address).length && addressError}
              isForCompleteProfile={isForVet}
            />
          </Form.Item>

          {!isMobile && (
            <AdditionalInfo
              propertyType={propertyType}
              setPropertyType={setPropertyType}
              isForVet={isForVet}
            />
          )}
        </Col>
      )}
      {!placeId && <Col xs={24} sm={24} md={2} lg={2} xl={2} />}
      {!isForVet && (
        <Col
          xs={24}
          sm={24}
          md={placeId ? 24 : 10}
          lg={placeId ? 24 : 10}
          xl={placeId ? 24 : 10}
          className={`${placeId ? 'calendar-in-the-center' : ''}`}>
          {((!calendarIsDisabled && timeSlots && !Object.keys(dates)?.length) ||
            (!addressError && vetIsOccupied)) && (
            <Text className="no-slots-text">
              {t('no.slots.error', {
                user: `${vet?.user?.firstName} ${vet?.user?.lastName}`
              })}
            </Text>
          )}
          <div className="address-calendar-wrapper">
            <p className={`choose-date ${calendarIsDisabled && 'disabled'}`}>{t('choose.a.day')}</p>
            <Calendar
              value={defaultDate}
              fullscreen={false}
              onChange={(current) => {
                const date = dayjs(current).format('YYYY-MM-DD');
                setSelectedDate(date);

                if (dates[date] && dates[date].length === 1) {
                  setSelectedTimeAndVetId(dates[date]?.[0], dates[date]?.vet?.uid);
                } else setSelectedTimeAndVetId(null);

                form.setFieldsValue({
                  date
                });
              }}
              disabledDate={(current) => {
                return calendarIsDisabled
                  ? current
                  : !dates[current.format('YYYY-MM-DD')] ||
                      dayjs().diff(dayjs(current), 'days') > 0;
              }}
              className={`calendar ${
                calendarIsDisabled ? 'disabled-calendar' : 'enabled-calendar'
              }`}
            />
          </div>
          {selectedDate && dates[selectedDate] && (
            <Form.Item disabled>
              <div className="time-slot-wrapper">
                <span className="time-slot-label">
                  {t('time.slots.for.date')}: {dayjs(selectedDate).format('MM/DD/YY')}
                </span>
              </div>
              <div className="time-wrapper">
                {sortByStartTime(dates[selectedDate])?.map((timeSlot, index) => {
                  return (
                    <div
                      key={index}
                      className={`time-item ${selectedTime?.uid === timeSlot.uid && 'selected'}`}
                      onClick={() => setSelectedTimeAndVetId(timeSlot, timeSlot?.vet?.uid)}>
                      <span className="time-text">
                        {getTime(timeSlot?.startTime)} -{getTime(timeSlot?.endTime)}
                      </span>
                      {vet?.vetAdmins?.length > 0 && vet?.vetAdmins[0].isMultiVetActive && (
                        <div className="caregiver-text">
                          <span>{`${timeSlot?.vet?.user?.firstName} ${timeSlot?.vet?.user?.lastName}`}</span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <p className="value-text">{t('time.value.text')}</p>
            </Form.Item>
          )}
          {isMobile && !placeId && (
            <AdditionalInfo propertyType={propertyType} setPropertyType={setPropertyType} />
          )}
        </Col>
      )}
    </Row>
  );
};

export default memo(AddressStep);
