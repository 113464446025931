import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from 'antd';
import AddressAutocomplete from 'components/vet-components/address-autocomplete/AddressAutocomplete';
import { preferenceTitlesAndDescriptions, preferenceTypeConstants } from 'constants/preferences';
import Modal from 'components/common/modal/Modal';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import { isJsonString } from 'utils/helpers';

const { Title, Text } = Typography;

const PreferenceAddress = ({ uid, address, onChange, onSelect, isFirstChild, description }) => {
  const { isMobile } = mediaQueriesList();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localValue, setLocalValue] = useState(
    isJsonString(address) ? JSON.parse(address) : address
  );

  const closeModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };

  const openModal = () => setIsModalOpen(true);

  const handleChange = (area) => {
    const newAddress = {
      city: area.city,
      countryCode: area.countryCode,
      street: area.street,
      description: area.description,
      zipCode: area.zipCode,
      lat: area.lat,
      lng: area.lng
    };
    if (isMobile) setLocalValue(JSON.stringify(newAddress));
    else {
      onSelect(JSON.stringify(newAddress), uid, preferenceTypeConstants.ANOTHER);
    }
  };

  const saveChanges = (e) => {
    e.stopPropagation();
    onSelect(localValue, uid, preferenceTypeConstants.ANOTHER);
    setIsModalOpen(false);
  };

  const addressComponent = useMemo(() => {
    return (
      <AddressAutocomplete
        className="address-autocomplete-input"
        address={address}
        onSelect={handleChange}
      />
    );
  }, [address, onChange, onSelect]);

  const titleComponent = useMemo(() => {
    return (
      <Title level={3} className="notification-title settings-text">
        {preferenceTitlesAndDescriptions[description]?.title}
      </Title>
    );
  }, []);

  const subTitleComponent = useMemo(() => {
    return (
      <Text className="notification-description settings-text">
        {preferenceTitlesAndDescriptions[description]?.description}
      </Text>
    );
  }, []);

  return (
    <div
      className={`notification-wrapper d-block ${isFirstChild ? 'first-child' : ''}`}
      onClick={() => (isMobile ? openModal() : {})}>
      <div className="notification-content">
        {titleComponent}
        {subTitleComponent}
      </div>
      {!isMobile && addressComponent}

      {/* Modal is only For Mobile Screen */}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          isMobile
          autoHeight
          scrollable
          withOverflow>
          <div className="modal-wrapper route-address-modal">
            {titleComponent}
            {subTitleComponent}
            {addressComponent}
            <div className="modal-footer">
              <Button className="btn" onClick={closeModal}>
                {t('preferences.cancel')}
              </Button>
              <Button type="primary" onClick={saveChanges}>
                {t('preferences.save')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PreferenceAddress;
