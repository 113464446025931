import React, { useEffect, useState } from 'react';
import { Descriptions, Tag } from 'antd';
import './styles.css';
import SingleAppointmentDetails from './SingleAppointmentDetails';
import { parseTimeSlotHour, Modal, getName } from '_fsd/shared';
import { useTranslation } from 'react-i18next';

const EventDetailsModal = ({ isModalOpen, setIsModalOpen, event, forceCloseModal }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('Details');
  const [isTimeslotEventType, setIsTimeslotEventType] = useState(false);

  const handleOk = () => {
    setIsModalOpen();
  };

  useEffect(() => {
    setIsTimeslotEventType(() => {
      if (event != false) {
        return event?.eventType == 'TimeslotEvent' ? true : false;
      }
    });
    // getTitle();
  }, [event]);

  useEffect(() => {
    isTimeslotEventType
      ? setTitle(t('admin.schedule.schedule_modal.title_timeslot'))
      : setTitle(t('admin.schedule.schedule_modal.title_timeslot'));
  }, [isTimeslotEventType]);

  return (
    <Modal
      title={title}
      className="admin-event-modal"
      isOpen={isModalOpen}
      onConfirm={handleOk}
      onClose={handleOk}
      forceRender
      footerProps={{ displayConfirm: false }}>
      {
        /* { isTimeslotEventType && */
        <div className="admin-event-modal-content">
          <Descriptions className="timeslot-details" column={1} labelStyle={{ fontWeight: 600 }}>
            {(event?.timeSlot?.isFull || event?.timeSlot?.isLocked) && (
              <Descriptions.Item label="">
                {event?.timeSlot?.isFull && <Tag color="red">Full</Tag>}
                {event?.timeSlot?.isLocked && <Tag color="yellow">Locked</Tag>}
              </Descriptions.Item>
            )}
            <Descriptions.Item
              label={t('admin.schedule.schedule_modal.timeslot_details.timeslot_name_lable')}>
              {event.timeSlot?.name}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('admin.schedule.schedule_modal.timeslot_details.location_lable')}>
              {event?.timeSlot?.workingAreas[0]?.polygon?.name || ''}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('admin.schedule.schedule_modal.timeslot_details.date_lable')}>
              {new Date(event?.timeSlot?.date).toLocaleDateString()}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('admin.schedule.schedule_modal.timeslot_details.hour_lable')}>
              {`${parseTimeSlotHour(event?.timeSlot?.startTime)} - ${parseTimeSlotHour(event?.timeSlot?.endTime)}`}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('admin.schedule.schedule_modal.timeslot_details.caregiver_lable')}>
              {`${getName(event.timeSlot?.vet.user)}`}
            </Descriptions.Item>
          </Descriptions>

          <div className="appointments-title">
            {isTimeslotEventType && (
              <p>
                <h3>
                  {t('admin.schedule.schedule_modal.timeslot_details.title_appointments_list')}
                  {event?.appointments?.length > 0 &&
                    ` (${event?.appointments?.length} households)`}
                </h3>
              </p>
            )}
            {!isTimeslotEventType && (
              <p>
                <h3>
                  {t('admin.schedule.schedule_modal.title_appointment')}{' '}
                  {event?.appointments?.length > 0}
                </h3>
              </p>
            )}
          </div>
          {event?.appointments?.length > 0 &&
            event?.appointments?.map((appointment) => {
              return (
                <SingleAppointmentDetails
                  key={appointment.uid}
                  appointment={appointment}
                  event={event}
                  forceCloseModal={forceCloseModal}
                  isTimeslotEventType={isTimeslotEventType}
                />
              );
            })}
          {event?.appointments?.length === 0 && (
            <div className="no-appointments">
              <p>
                <h4>
                  {t(
                    'admin.schedule.schedule_modal.timeslot_details.appointment_list.no_appointments'
                  )}
                </h4>
              </p>
            </div>
          )}
        </div>
      }
    </Modal>
  );
};

export default EventDetailsModal;
