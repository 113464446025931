import React from 'react';
import * as moment from 'moment';
import { InfoCircleFilled } from '@ant-design/icons';
import css from './RecurringTimeSlotRuleViewForm.module.css';

import { cssSpace as space, cssText as text } from '_fsd/shared';
import Popover from 'components/vet-admin/lib/popover/Popover';
import { Row } from 'antd';

import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-blue.svg';

import cls from 'classnames';

export const RecurringTimeSlotRuleViewForm = ({ rrule }) => {
  const { t } = useTranslation();

  const ruleText = rrule.toText();

  return (
    <>
      <Row>
        <div className={css.row}>
          <span className={cls(text.s12h20w4, text.opacity65)}>
            {t('recurring_time_slot.repeat_rule')}
          </span>
          <Popover
            content={
              <span className={cls(text.s14h22w4, css.tooltipText)}>
                {t('recurring_time_slot.non_editable_tooltip')}
              </span>
            }>
            <div className={cls(text.s12h18w6, css.tooltipTrigger)}>
              {t('recurring_time_slot.non_editable')}
              <InfoCircleFilled />
            </div>
          </Popover>
        </div>
        <div className={cls(css.ruleContainer, space.mb8)}>
          <div className={space.mb10}>
            <div className={css.title}>
              <span className={text.s14h22w5}>
                {ruleText.charAt(0).toUpperCase() + ruleText.slice(1)}
              </span>
            </div>
          </div>
          <div className={cls(css.dateContainer, text.s12h22w4)}>
            <div className={css.iconWithText}>
              <CalendarIcon className={space.mr4} />
              {t('recurring_time_slot.start_date')}
            </div>
            {moment(rrule.options.dtstart).format('DD/MM/YYYY')}
          </div>
          <div className={cls(css.dateContainer, text.s12h22w4)}>
            <div className={css.iconWithText}>
              <CalendarIcon className={space.mr4} />
              {t('recurring_time_slot.end_date')}
            </div>
            {rrule.options.until
              ? moment(rrule.options.until).format('DD/MM/YYYY')
              : rrule.options.count
                ? `${t('recurring_time_slot.after')} ${rrule.options.count} ${t('recurring_time_slot.occurrences')}`
                : t('recurring_time_slot.never')}
          </div>
        </div>
      </Row>
    </>
  );
};
