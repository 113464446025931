import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Frequency, RRule } from 'rrule';
import * as moment from 'moment';
import { getPointsFromVet } from '_fsd/features/timeslot-editor';

const schema = Yup.object({
  name: Yup.string().max(100).required(),
  startTime: Yup.string().max(8).required(),
  endTime: Yup.string().max(8).required(),
  rule: Yup.string().required(),
  date: Yup.string().nullable(),
  maxAppointments: Yup.number().min(0).nullable(),
  isRecurring: Yup.boolean().required(),
  isSingle: Yup.boolean().nullable(),
  startPoint: Yup.object({
    lat: Yup.number().required(),
    lng: Yup.number().required()
  }).required(),
  endPoint: Yup.object({
    lat: Yup.number().required(),
    lng: Yup.number().required()
  }).required(),
  useDefaultPoints: Yup.boolean().required(),
  workingAreas: Yup.array().min(1).required(),
  isDefaultLimit: Yup.boolean().required()
});

const getInitialValues = (data, isSingleTimeslot, date, vet) => {
  const { start, end } = getPointsFromVet(vet);
  if (!data) {
    return {
      name: null,
      startTime: undefined,
      endTime: undefined,
      weekDay: null,
      rule: isSingleTimeslot
        ? new RRule({
            freq: Frequency.DAILY,
            dtstart: moment(moment(date).format('YYYYMMDD') + 'T120000Z').toDate(),
            count: 1,
            interval: 1
          }).toString()
        : new RRule({
            freq: Frequency.WEEKLY,
            dtstart: moment(moment().format('YYYYMMDD') + 'T120000Z').toDate(),
            byweekday: [RRule.MO, RRule.TH, RRule.WE, RRule.TU, RRule.FR]
          }).toString(),
      date: date || new Date(),
      maxAppointments: null,
      isRecurring: !isSingleTimeslot,
      startPoint: start || null,
      endPoint: end || null,
      useDefaultPoints: true,
      workingAreas: [],
      isDefaultLimit: true,
      isIncludeChecked: false,
      isSingle: false,
      vet: undefined
    };
  }
  return {
    uid: data.uid,
    name: data.name,
    startTime: data.startTime,
    endTime: data.endTime,
    weekDay: data.weekDay,
    rule: isSingleTimeslot
      ? new RRule({
          freq: Frequency.DAILY,
          dtstart: moment(moment(date || new Date()).format('YYYYMMDD') + 'T120000Z').toDate(),
          count: 1,
          interval: 1
        }).toString()
      : data.rule,
    date: date ? date : data.date,
    maxAppointments: data.maxAppointments,
    isRecurring: isSingleTimeslot ? false : data.isRecurring,
    startPoint: data.startPoint ?? start,
    endPoint: data.endPoint ?? end,
    useDefaultPoints: true,
    workingAreas: data.workingAreas || [],
    isDefaultLimit: data.maxAppointments === null,
    isIncludeChecked: true,
    isSingle: data.isSingle,
    vet: data.vet || undefined
  };
};

/**
 *
 * @param {*} data initial ts data
 * @param {*} formikProps formik options
 * @param {*} isSingleTimeslot is single
 * @param {*} date optional, set date for ts, ignore date from passed ts
 * @param {*} vet optional, used to set start/end point
 * @return {*} formik object
 */
export const useCreateForm = (data, formikProps = {}, isSingleTimeslot, date, vet) => {
  return useFormik({
    validationSchema: schema,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
    initialValues: getInitialValues(data, isSingleTimeslot, date, vet),
    ...formikProps
  });
};
