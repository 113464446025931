import { useQuery, gql } from '@apollo/client';

export const useVets = (options = { variables: { search: '' } }) => {
  const { data, ...rest } = useQuery(getVetsQuery, {
    variables: {
      ...(options?.variables || {})
    },
    ...options
  });
  return { data: data?.getVets, ...rest };
};

const getVetsQuery = gql`
  query getVetsAdmin($search: String!, $order: OrderVets) {
    getVets(search: $search, order: $order) {
      uid
      status
      user {
        uid
        avatarUrl
        firstName
        lastName
        email
        phonePrefix
        phoneNumber
        bio
        userSettings {
          itemValue
          setting {
            description
          }
        }
        address {
          uid
          city
          country {
            uid
            name
          }
        }
      }
      experience
      pets {
        uid
        name
      }
      licenseNumber
      licensedDate
      licenseExpiration
      languages {
        uid
        code
        name
      }
      commuteMethod
      createdAt
      activeCustomersCount
      vetAdmins {
        uid
        integrations {
          uid
          status
          pim {
            uid
            name
          }
        }
      }
    }
  }
`;
