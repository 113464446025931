import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { cssText, Button } from '_fsd/shared';
import css from './UserContactInfoPopover.module.css';
import { Popover, message } from 'antd';
import cn from 'classnames';

export const UserContactInfoPopover = ({ phone, email, city, street, children, onOpenEdit }) => {
  const { t } = useTranslation();

  const onCopy = (event) => {
    let value = '';
    if (event.currentTarget.name === 'phone') value = phone;
    if (event.currentTarget.name === 'email') value = email;

    navigator.clipboard.writeText(value);
    message.success(t('copied.to.clipboard'));
  };

  return (
    <Popover
      zIndex={100}
      content={
        !onOpenEdit && !city && !phone && !email && !street ? null : (
          <div className={css.cellPopover}>
            {(onOpenEdit || phone) && (
              <div className={css.singlePopover}>
                <div className={cn(cssText.s14w6l18, css.itemName)}>
                  {t('owner_contact_info_popover.phone')}
                </div>
                <div>
                  {!phone ? (
                    <Button type="link" onClick={() => onOpenEdit?.()}>
                      <span className={css.addInfo}>
                        {t('owner_contact_info_popover.add_info')}
                      </span>
                    </Button>
                  ) : (
                    <>
                      <span className={css.singlePopOverObject}>
                        <a href={`tel:${phone}`}>{phone}</a>
                      </span>
                      <Button
                        name="phone"
                        type="text"
                        icon={<CopyOutlined className={css.singlePopOverObject} />}
                        onClick={onCopy}
                        shape="circle"
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            {(onOpenEdit || email) && (
              <div className={css.singlePopover}>
                <div className={cn(cssText.s14w6l18, css.itemName)}>
                  {t('owner_contact_info_popover.email')}
                </div>
                {!email ? (
                  <Button type="link" onClick={() => onOpenEdit?.()}>
                    <span className={css.addInfo}>{t('owner_contact_info_popover.add_info')}</span>
                  </Button>
                ) : (
                  <div>
                    <span className={css.singlePopOverObject}>
                      <a href={`mailto:${email}`}>{email}</a>
                    </span>
                    <Button
                      name="email"
                      type="text"
                      icon={<CopyOutlined className={css.singlePopOverObject} />}
                      onClick={onCopy}
                      shape="circle"
                    />
                  </div>
                )}
              </div>
            )}
            {city && (
              <div className={css.singlePopover}>
                <div className={cn(cssText.s14w6l18, css.itemName)}>
                  {t('owner_contact_info_popover.city')}
                </div>
                <span className={cn(css.singlePopOverObject, css.itemWithOutCopyIcon)}>{city}</span>
              </div>
            )}
            {street && (
              <div className={css.singlePopover}>
                <div className={cssText.s14w6l18}>{t('owner_contact_info_popover.street')}</div>
                <span className={cn(css.singlePopOverObject, css.itemWithOutCopyIcon)}>
                  {street}
                </span>
              </div>
            )}
          </div>
        )
      }>
      <div>{children}</div>
    </Popover>
  );
};
