import React from 'react';
import { useTranslation } from 'react-i18next';
import css from './ActionColumn.module.css';
import { CopyOutlined, EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, cssText, Dropdown } from '_fsd/shared';

import cls from 'classnames';

export const ActionColumn = ({
  record,
  setSelectedTimeSlot,
  setIsRecurringTimeSlotPageOpen,
  onRemove,
  setIsCopying
}) => {
  const { t } = useTranslation();

  const items = [
    {
      key: 0,
      label: (
        <div
          className={css.actionItem}
          onClick={() => {
            setSelectedTimeSlot({
              ...record,
              uid: null
            });
            setIsRecurringTimeSlotPageOpen(true);
            setIsCopying(true);
          }}>
          <div className={css.actionColumnDropdown}>
            <CopyOutlined className="copy-icon" />
            <span className={cssText.s14w5h20i}>{t('vet_timeslots.copy_ts')}</span>
          </div>
        </div>
      )
    },
    {
      key: 1,
      label: (
        <div
          className={css.actionItem}
          onClick={() => {
            setSelectedTimeSlot(record);
            setIsRecurringTimeSlotPageOpen(true);
          }}>
          <div className={css.actionColumnDropdown}>
            <EditOutlined className="write-icon" />
            <span className={cssText.s14w5h20i}>{t('vet_timeslots.edit_ts')}</span>
          </div>
        </div>
      )
    },
    {
      key: 2,
      label: (
        <div
          className={css.actionItem}
          onClick={() => {
            onRemove(record.uid);
          }}>
          <div className={cls(css.actionColumnDropdown, css.iconRemove)}>
            <DeleteOutlined className="trash-icon" color="red" />
            <span className={cssText.s14w5h20i}>{t('vet_timeslots.delete_ts')}</span>
          </div>
        </div>
      )
    }
  ];
  return (
    <div className={css.actionColumn}>
      <Dropdown
        menu={{ items }}
        className={css.dropDown}
        trigger={['click']}
        getPopupContainer={() => document.getElementById('ID_LAYOUT_SCROLLABLE')}>
        <Button icon={<MoreOutlined />} type="text" shape="circle" />
      </Dropdown>
    </div>
  );
};
