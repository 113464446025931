import { gql } from 'graphql.macro';
import { useMutation } from '@apollo/client';

export const useCreateTimeslotFromRecurring = (options) => {
  return useMutation(query, {
    ...options,
    refetchQueries: ['getTimeSlots', 'getAuthVet', ...(options?.refetchQueries || [])]
  });
};

const query = gql`
  mutation editSingleTimeSlotInRecurringTimeSlot(
    $record: EditSingleTimeSlotInRecurringTimeSlotRecord!
  ) {
    timeslot: editSingleTimeSlotInRecurringTimeSlot(record: $record) {
      uid
    }
  }
`;
