import React from 'react';
import css from './AddressStepNew.module.css';
import { isValidAddress } from '_fsd/shared';
import { RescheduleWrapper } from '_fsd/widgets';
import { AddressNew } from '_fsd/features/edit-pet-owner-address-modal';

export const AddressStepNew = (props) => {
  const {
    filters,
    timeslotActions,
    address,
    addressError,
    isForVet,
    onSelect,
    onChange,
    propertyType,
    setPropertyType,
    vet
  } = props;

  return (
    <div className={css.container}>
      <AddressNew
        address={address}
        addressError={addressError}
        isForVet={isForVet}
        onSelect={onSelect}
        onChange={onChange}
        propertyType={propertyType}
        setPropertyType={setPropertyType}
      />
      {isValidAddress(address) ? (
        <RescheduleWrapper
          isVetAdmin
          defaultReschedule={false}
          filters={filters}
          timeslotActions={timeslotActions}
          theme="light"
          currentAddress={address}
          vetId={vet?.uid}
          displayFooter={false}
          displayHeader={false}
          close={() => {}}
        />
      ) : null}
    </div>
  );
};
