import React, { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '_fsd/shared';
import arrow from 'assets/icons/arrow-down-blue.svg';

import * as S from './components';
import './styles.css';
import { TimeslotForm } from '_fsd/widgets';
import css from './AddTimeslotModal.module.css';

const AddTimeslotModal = ({
  closeModal,
  updateTimeslotData,
  calendarDate,
  refetchAuthVet,
  vet,
  isCopying
}) => {
  const [isRecommendationsOpen, setIsRecommendationsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Modal
      force
      className={css.modal}
      isOpen={true}
      onClose={closeModal}
      customFooter={() => null}
      title={t('add.timeslot')}>
      <S.Recommendations>
        <S.Heading onClick={() => setIsRecommendationsOpen((prev) => !prev)}>
          {t('timeslot_recommendations.recommendations')}
          <S.DropdownImg src={arrow} isRecommendationsOpen={isRecommendationsOpen} />
        </S.Heading>

        <S.UL isRecommendationsOpen={isRecommendationsOpen}>
          <S.SingleReccomendation>
            {t('timeslot_recommendations.make_short')}
          </S.SingleReccomendation>
          <S.SingleReccomendation>
            {t('timeslot_recommendations.morning_or_evening')}
          </S.SingleReccomendation>
        </S.UL>
      </S.Recommendations>
      <TimeslotForm
        isCopying={isCopying}
        date={calendarDate}
        vetId={vet?.uid}
        refetchTimeSlots={() => {
          refetchAuthVet();
        }}
        onSave={() => closeModal()}
        selectedTimeSlot={updateTimeslotData}
      />
    </Modal>
  );
};

export default memo(AddTimeslotModal);
