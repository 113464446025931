import React from 'react';
import { cssText as text } from '_fsd/shared';
import { parseTimeSlotHour } from 'utils/helpers';
import css from './RecurringTimeSlotList.module.css';
import { ActionColumn } from './ActionColumn';
import cls from 'classnames';
import { filterAndConvertRuleToText } from '../lib';
import LoaderWrapper from '../../../../components/loaderWrapper/LoaderWrapper';

export const RecurringTimeSlotList = ({
  timeSlots,
  loading,
  setSelectedTimeSlot,
  setIsRecurringTimeSlotPageOpen,
  setIsCopying,
  onRemove
}) => {
  return (
    <LoaderWrapper isLoading={loading} className={css.loader}>
      <div className={css.container}>
        {timeSlots?.map((timeSlot) => {
          const ruleText = filterAndConvertRuleToText(timeSlot.rule);
          return (
            <div key={timeSlot.uid} className={cls(css.timeSlotCard)}>
              <div className={css.labels}>
                <div className={text.s16h22w5}>{timeSlot.name}</div>
                <div className={cls(text.s14h18w4, css.opacity70)}>{ruleText}</div>
              </div>
              <div className={css.timeAndAction}>
                <div className={cls(css.opacity70)}>
                  {`${parseTimeSlotHour(
                    timeSlot.startTime
                  )} - ${parseTimeSlotHour(timeSlot.endTime)}`}
                </div>
                <ActionColumn
                  record={timeSlot}
                  setSelectedTimeSlot={setSelectedTimeSlot}
                  setIsRecurringTimeSlotPageOpen={setIsRecurringTimeSlotPageOpen}
                  onRemove={onRemove}
                  setIsCopying={setIsCopying}
                />
              </div>
            </div>
          );
        })}
      </div>
    </LoaderWrapper>
  );
};
