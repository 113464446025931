import React, { useEffect, useMemo, useState } from 'react';
import css from './Recommender.module.css';
import { message } from 'antd';
import cls from 'classnames';
import { useMutation } from '@apollo/client';
import moment from 'moment-timezone';
import startCase from 'lodash/startCase.js';
import { useMediaQuery } from 'react-responsive';
import { rescheduleVisitMutation } from 'gql';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-blue.svg';
import Selector from 'components/common/selector/Selector';
import { months } from 'utils/helpers';
import { TimeslotRecomender as Timeslot } from './Timeslot';
import { Providers } from './Providers';
import petSvg from 'assets/icons/pet.svg';

import { Button, Label, cssText as text } from '_fsd/shared';
import { MapView } from '_fsd/features';
import { FilterOption } from './FilterOption';
import { getStartEndPoints } from '_fsd/entities/vet';
import { useTranslation } from 'react-i18next';
import LoaderWrapper from '../../../../../components/loaderWrapper/LoaderWrapper';
import { useRescheduleData } from '../../api';
export const Recommender = (props) => {
  const {
    theme,
    timeslotActions,
    filters,
    displayFooter,
    appointment,
    vetId,
    currentAddress,
    close,
    isVetAdmin
  } = props;
  const { t } = useTranslation('reschedule');

  const [displayFilters, setDisplayFilters] = useState(false);
  const isColumnView = useMediaQuery({
    maxWidth: 1000
  });

  const [isLoading, setLoading] = useState(false);
  const [selectedDate, _setSelectedDate] = useState(moment().startOf('day'));
  const [selectedSort, setSelectedSort] = useState(options[0]);
  const setSelectedDate = (value) => {
    _setSelectedDate(value);
    timeslotActions.setActiveTimeslot();
  };

  const { vets, filteredTimeslots, loadingTimeslots } = useRescheduleData({
    selectedSort,
    selectedDate,
    timeslotActions,
    filters,
    vetId,
    currentAddress
  });

  const [points, activeTs] = useMemo(() => {
    const activeTimeslot = filteredTimeslots?.find(
      (ts) => ts.timeSlot._key === timeslotActions.activeTimeslot?._key
    );
    return [
      getStartEndPoints({
        route: activeTimeslot?.routes?.[0]?.route,
        timeslot: activeTimeslot?.timeSlot,
        vet: activeTimeslot?.timeSlot?.vet
      }),
      activeTimeslot
    ];
  }, [timeslotActions.activeTimeslot?._key, filteredTimeslots]);
  // reschedule
  const [reschedule] = useMutation(rescheduleVisitMutation);
  const rescheduleVisit = () => {
    setLoading(true);
    reschedule({
      variables: {
        record: {
          date: moment(activeTs.timeSlot.date).format('YYYY-MM-DD'),
          appointmentId: appointment.uid,
          timeSlotId: activeTs.timeSlot.uid
        }
      },
      refetchQueries: ['getCalendarEvents', 'getTimeSlots', 'getAppointmentsByDate']
    })
      .then(() => {
        setLoading(false);
        message.success('Appointment rescheduled successfully');
        close();
      })
      .catch((e) => {
        message.error(e.message);
        setLoading(false);
      });
  };

  const decreaseMonth = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const prevMonth = moment(selectedDate.format()).subtract(1, 'months').startOf('month').format();
    if (prevMonth < moment().format()) {
      setSelectedDate(moment().startOf('day'));
    } else {
      setSelectedDate(moment(prevMonth));
    }
  };
  const increaseMonth = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const nextMonth = moment(selectedDate.format()).add(1, 'months').startOf('month').format();
    setSelectedDate(moment(nextMonth));
  };

  const { nextMonthButtonDisabled, prevMonthButtonDisabled } = useMemo(() => {
    const today = moment();
    const left =
      selectedDate.year() < today.year() ||
      (selectedDate.year() === today.year() && today.month() >= selectedDate.month());
    // const right = selectedDate.format() > today.add(2, 'months').format();
    return {
      nextMonthButtonDisabled: false,
      prevMonthButtonDisabled: left
    };
  }, [selectedDate]);

  useEffect(() => {
    if (!timeslotActions.activeTimeslot) {
      timeslotActions.setActiveTimeslot(filteredTimeslots[0]?.timeSlot);
    }
  }, [filteredTimeslots]);

  return (
    <>
      <div className={cls(css.container, { [css.noPadding]: theme === 'light' })}>
        <div className={css.filtersColumn}>
          <span
            className={cls(css.filters, text.s16w6l18)}
            onClick={() => isColumnView && setDisplayFilters(!displayFilters)}>
            {t('filters')}
            {isColumnView && (
              <LeftArrow className={cls(css.arrowFilter, { [css.arrowDown]: displayFilters })} />
            )}
          </span>
          <div
            className={cls(css.filtersContent, {
              [css.filtersContentHide]: isColumnView && !displayFilters
            })}>
            <FilterOption
              disabled={isLoading}
              value={filters.displayFullTS}
              setValue={filters.setFullTS}
              label={t('show_full_ts')}
            />
            <FilterOption
              disabled={isLoading}
              value={filters.displayLocked}
              setValue={filters.setDisplayLocked}
              label={t('show_locked_ts')}
            />
            <FilterOption
              disabled={isLoading}
              value={filters.displayTSNotInArea}
              setValue={filters.setDisplayTSNotInArea}
              label={t('show_all_areas')}
            />
            {isVetAdmin ? null : (
              <FilterOption
                disabled={isLoading}
                value={filters.displayVets}
                setValue={filters.setDisplayVets}
                label={t('show_other_vets')}
              />
            )}
            <Providers
              displayVets={filters.displayVets}
              isLoading={isLoading}
              vets={vets}
              selectedVets={filters.selectedVets}
              setSelectedVets={filters.setSelectedVets}
            />
          </div>
        </div>
        <div className={css.timeslotsWrapper}>
          <div className={css.row}>
            <div className={css.monthSelectorWrapper}>
              <button
                className={cls(css.arrow, { [css.arrowDisabled]: prevMonthButtonDisabled })}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}>
                <LeftArrow />
              </button>
              <div className={css.month}>{startCase(months[selectedDate.month()])}</div>
              <button
                className={cls(css.arrow, css.arrowRight, {
                  [css.arrowDisabled]: nextMonthButtonDisabled
                })}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}>
                <LeftArrow />
              </button>
            </div>
            <div className={css.selectorWrapper}>
              <span className={cls(css.sort, text.s12w400l24)}>{t('sort_by')}&nbsp;</span>
              <Selector
                value={selectedSort}
                options={options}
                onChange={(value) => {
                  setSelectedSort(value);
                }}
                hideLabel
                noError
                isForTimeSlot
              />
            </div>
          </div>
          <div className={css.timeslots}>
            <LoaderWrapper isLoading={loadingTimeslots} className={css.loaderTimeslots}>
              {filteredTimeslots.map((ts) => (
                <Timeslot
                  active={ts.timeSlot._key === timeslotActions.activeTimeslot?._key}
                  disabled={ts.timeSlot._key === timeslotActions.defaultTimeslot?._key}
                  onClick={() => timeslotActions.setActiveTimeslot(ts.timeSlot)}
                  key={ts.timeSlot._key}
                  timeslot={ts}
                />
              ))}
              {!loadingTimeslots && !filteredTimeslots.length ? (
                <div className={css.petIcon}>
                  <img src={petSvg} alt="bg" />
                  <Label className={css.labelNoTimeslots}>{t('no_timeslots')}</Label>
                </div>
              ) : null}
            </LoaderWrapper>
          </div>
        </div>
        <div className={css.map}>
          <MapView
            currentAppointmentLocation={currentAddress}
            existingAppointments={activeTs?.routes?.[0]?.route?.appointmentRoutes}
            workingAreas={activeTs?.timeSlot?.workingAreas}
            startEndPoints={points}
          />
        </div>
      </div>
      {displayFooter ? (
        <div className={css.footer}>
          {!timeslotActions.activeTimeslot?._key && (
            <div className={cls(css.error, text.s14w4l22)}>{t('select_timeslot')}</div>
          )}
          <Button
            type="primary"
            colorScheme="blue"
            disabled={
              !timeslotActions.activeTimeslot?._key ||
              timeslotActions.activeTimeslot?._key === timeslotActions.defaultTimeslot?._key ||
              isLoading
            }
            onClick={rescheduleVisit}>
            {t('btn_reschedule')}
          </Button>
        </div>
      ) : null}
    </>
  );
};

const options = [
  { label: 'Suggested', value: 'suggested' },
  { label: 'Date', value: 'date_earliest' },
  { label: 'Distance', value: 'distance_nearest' }
];
