import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import { timeSlotsToDateObject } from 'common_repo/utils/helpers';
import { timeSlotsFilterHelper } from './timeSlot';

const DATE_FORMAT_FULL = 'YYYY-MM-DD';

export const getTimeslotsInDate = ({
  loading,
  timeslots,
  filters = {},
  timeslotActions = {},
  setDates = () => {},
  startDate,
  isForAdminCalendar
}) => {
  return useMemo(() => {
    if (!loading) {
      let datesRaw = {};
      if (!timeslots || !timeslots.length) {
        datesRaw = {};
      } else if (filters.displayFullTS) {
        datesRaw = timeSlotsToDateObject({
          timeslots,
          startDate,
          isForVet: true,
          isForAdminCalendar,
          undefined,
          shouldDeleteFullTimeSlots: false
        });
      } else {
        datesRaw = timeSlotsToDateObject({
          timeslots: timeSlotsFilterHelper(timeslots, undefined),
          startDate,
          isForVet: true,
          isForAdminCalendar,
          undefined,
          shouldDeleteFullTimeSlots: false
        });
      }
      const date = timeslotActions.calendarDate?.format(DATE_FORMAT_FULL);
      const filtered = datesRaw[date] || [];
      const ordered = orderBy(filtered, ['updatedAt'], ['desc']);
      if (
        timeslotActions?.setActiveTimeslot &&
        !ordered.some((ts) => ts.uid === timeslotActions.activeTimeslot?.uid)
      ) {
        timeslotActions?.setActiveTimeslot();
      }
      setDates(datesRaw);
      return ordered;
    }
    return [];
  }, [timeslotActions.calendarDate, loading, filters.displayFullTS, timeslots]);
};
