import React from 'react';

import * as S from './components';

const LoaderWrapper = ({ isLoading, children, isTimeline, style, className }) => {
  return isLoading ? (
    <S.SpinnerWraper isTimeline={isTimeline} style={style} className={className}>
      <S.Spinner />
    </S.SpinnerWraper>
  ) : (
    children
  );
};

export default LoaderWrapper;
