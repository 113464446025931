import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Layout, Tooltip, Checkbox } from 'antd';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import CopyRight from '../copyright/CopyRight';
import missingCss from '_fsd/entities/pet-owner/ui/MissingInfo/MissingInfo.module.css';
import { MissingInfo } from '_fsd/entities/pet-owner/ui/MissingInfo/MissingInfo';

import { Modal } from '_fsd/shared';
import { isPetOwnerRole } from '_fsd/entities/user';

function StepperFooter({
  current,
  phoneNumberError,
  setPhoneNumberError,
  addressError,
  selectedDate,
  selectedTime,
  errorMessage,
  steps,
  changeStep,
  form,
  isLoading,
  isSubmitting,
  auth,
  isBooking,
  vetIsOccupied,
  ignoreFirstStep,
  isForVet,
  goBack
}) {
  const { isMobile } = mediaQueriesList();
  const { t } = useTranslation();
  const { Content } = Layout;
  const [isInformClient, setIsInformClient] = useState(true);
  const [modalMissingInfo, setModalMissingInfo] = useState(false);

  const isPetOwner = isPetOwnerRole(auth);

  const renderButtonText = useMemo(() => {
    if (current === 2) return 'finish';
    return 'next';
  }, [current]);

  const handleMissingInfo = () => {
    if (isPetOwner) {
      form.submit();
      return;
    }
    if (current === 0 && (!form.getFieldValue('email') || !form.getFieldValue('phoneNumber'))) {
      setModalMissingInfo(true);
    } else {
      setModalMissingInfo(false);
      form.submit();
    }
  };

  return (
    <div className="footer-wrapper">
      {!isMobile && !isBooking && !isForVet && <CopyRight />}
      <Content className="text-right">
        <Form.Item noStyle shouldUpdate={true} htmlFor="submit">
          {({ getFieldsError, getFieldsValue }) => {
            const isDisabled = () => {
              switch (current) {
                case 0:
                  const phoneError = getFieldsError(['phoneNumber'])[0].errors.length;
                  if (Boolean(phoneError) !== phoneNumberError) {
                    setPhoneNumberError(!phoneNumberError);
                  }
                  const thirdStepValues = getFieldsValue(
                    isPetOwner ? ['firstName', 'lastName', 'email'] : ['firstName']
                  );

                  const thirdStepIsFilled =
                    Object.keys(thirdStepValues).filter((value) => thirdStepValues[value])
                      .length === (isPetOwner ? 3 : 1);
                  const errorsOnThirdStep =
                    getFieldsError(['firstName', 'lastName', 'email', 'phoneNumber']).filter(
                      ({ errors }) => errors.length
                    ).length > 0;
                  return !thirdStepIsFilled || errorsOnThirdStep;
                  break;
                case 1:
                  const errorOnFirstStep =
                    getFieldsError(['floor', 'apartment']).filter(({ errors }) => errors.length)
                      .length > 0;

                  if (ignoreFirstStep) {
                    return vetIsOccupied || !selectedTime?.uid || addressError;
                  }

                  return errorOnFirstStep || vetIsOccupied || !selectedTime?.uid || addressError;
                case 2:
                  const pet = getFieldsValue(['pets'])['pets'];
                  return !pet.length;

                default:
                  return addressError || !selectedDate || !selectedTime?.uid;
              }
            };

            const buttonIsDisabled = isLoading || isDisabled() || errorMessage;
            return (
              <div className="stepper-footer">
                {isForVet && renderButtonText === 'finish' && (
                  <div
                    className="checkbox-text-wrapper"
                    onClick={() => {
                      form.setFieldsValue({ isInformClient: !isInformClient });
                      setIsInformClient(!isInformClient);
                    }}>
                    <Checkbox checked={isInformClient} />
                    <span className="checkbox-text">{t('inform_client_by_email')}</span>
                  </div>
                )}
                {errorMessage && <span className="error-message">{errorMessage}</span>}
                {isForVet && current === 0 && (
                  <Button type="primary" onClick={goBack} className="back-btn">
                    {t('back')}
                  </Button>
                )}
                {current !== 0 && (
                  <Button
                    type="primary"
                    onClick={() => changeStep(current - 1)}
                    className="back-btn"
                    loading={isLoading}>
                    {t('back')}
                  </Button>
                )}
                {buttonIsDisabled ? (
                  <Tooltip
                    trigger={'hover'}
                    placement="topRight"
                    title={
                      <span>
                        {t(
                          current === 0
                            ? !selectedTime?.uid
                              ? 'please.select.timeslot'
                              : 'patient_details.please.fill.fields'
                            : 'patient_details.please.fill.fields'
                        )}
                      </span>
                    }>
                    <Button type="primary" onClick={handleMissingInfo} disabled={true}>
                      {t(renderButtonText)}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    type="primary"
                    disabled={isLoading || isSubmitting}
                    onClick={handleMissingInfo}>
                    {t(renderButtonText)}
                  </Button>
                )}
                {modalMissingInfo && (
                  <Modal
                    customFooter={() => null}
                    customHeader={() => null}
                    isOpen={modalMissingInfo}
                    force
                    className={missingCss.missingInfoModal}
                    onClose={() => setModalMissingInfo(false)}>
                    <MissingInfo
                      onSubmit={() => {
                        setModalMissingInfo(false);
                        form.submit();
                      }}
                      onClose={() => setModalMissingInfo(false)}
                      email={form.getFieldValue('email')}
                      phoneNumber={form.getFieldValue('phoneNumber')}
                    />
                  </Modal>
                )}
              </div>
            );
          }}
        </Form.Item>
      </Content>
    </div>
  );
}

export default StepperFooter;
