const MAP_DAY = [
  'Monday', // 0
  'Tuesday', // 1
  'Wednesday', // 2
  'Thursday', // 3
  'Friday', // 4
  'Saturday', // 5
  'Sunday', // 6
  'Day', // 7 [0, 1, 2, 3, 4, 5, 6]
  'Weekday', // 8 [0, 1, 2, 3, 4]
  'Weekend day' // 9 [5, 6]
];

export const getValueByWeekDay = (byweekday = []) => {
  if (byweekday?.length === 1) {
    return MAP_DAY[byweekday[0]];
  }
  if (byweekday?.length === 7) return MAP_DAY[7];
  if (byweekday?.length === 5) return MAP_DAY[8];
  if (byweekday?.length === 2) return MAP_DAY[9];
  return MAP_DAY[0];
};
