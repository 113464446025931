/**
 *
 * @param {object} param
 * @param {object} param.vet
 * @param {object} param.route
 * @param {object} param.timeslot
 * @return {object} points
 * @description if route exist - return points from route,
 *  else if timeslot exist -> return points from timeslot,
 *  else return points from vet.user.settings
 */
export const getStartEndPoints = ({ vet, route, timeslot }) => {
  let points = undefined;

  if (route && route?.startPoint) {
    points = {
      startPoint: route?.startPoint,
      endPoint: route?.endPoint
    };
  }
  if (!points && timeslot && timeslot.startPoint) {
    points = {
      startPoint: timeslot.startPoint,
      endPoint: timeslot.endPoint
    };
  }
  if (!points && vet?.user) {
    const startPoint = parseUserSettingStartPoint(vet?.user?.userSettings);
    const endPoint = parseUserSettingEndPoint(vet?.user?.userSettings);
    points = {
      startPoint,
      endPoint
    };
  }
  return points;
};

const KEY_DEFAULT_START_POINT = 'DEFAULT_START_POINT';
export const parseUserSettingStartPoint = (userSettings = {}) => {
  try {
    const settingItem = userSettings?.find(
      (setting) => setting?.setting?.description === KEY_DEFAULT_START_POINT
    );
    return JSON.parse(settingItem?.itemValue);
  } catch (e) {
    return {};
  }
};
const KEY_DEFAULT_END_POINT = 'DEFAULT_END_POINT';
export const parseUserSettingEndPoint = (userSettings = {}) => {
  try {
    const settingItem = userSettings?.find(
      (setting) => setting?.setting?.description === KEY_DEFAULT_END_POINT
    );
    return JSON.parse(settingItem?.itemValue);
  } catch (e) {
    return {};
  }
};
