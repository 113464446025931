import React, { useState, useMemo } from 'react';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import { AppointmentRouteStatus } from 'constants/client';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { getTimeslotsInUserAreaQuery } from '../../../gql';
import { visitIcon } from '../../../constants/visit';
import { VetStatus } from '../../../constants/enums';

import useTimelineContentBox from '../content-box/useTimelineContentBox';
import VisitModal from '../../../pages/dashboard/visit-modal/VisitModal';
import TimeSlotCalendar from '../../time-slot/TimeSlotCalendar';
import useVetCard from '../../vet-card/useVetCard';
import useTimelineAppointments from './useTimelineAppointments';
import TimelineContentBox from '../content-box/TimelineContentBox';
import TimelineTimeBox from '../time-box/TimeBox';
import VisitInfo from '../visit-info/VisitInfo';

import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import NoMeetings from 'components/no-meetings/NoMeetings';

import CalendarDarkImg from 'assets/images/calendar-grey.svg';
import CalendarImg from 'assets/images/calendar-colored.svg';

import * as S from './components';
import moment from 'moment/moment';
import { getStartDate } from '_fsd/shared';
const TimelineAppointments = ({
  timeline,
  loading,
  year,
  setVetUid,
  singlePet,
  submitCalendar,
  time
}) => {
  const { t } = useTranslation();
  const [monthDate, setMonthDate] = useState(new Date());
  const {
    futureTimeline,
    pastTimeline,
    selectedTimeSlot,
    setSelectedTimeSlot,
    isFiltered,
    isFutureTimeSlot,
    isRescheduleAvailable
  } = useTimelineAppointments(timeline, year, submitCalendar);

  const [selectedVetUid, setSelectedVetUid] = useState(null);
  const [isVisitModalOpen, setIsVisitModalOpen] = useState(false);

  const { isMobile } = mediaQueriesList();

  const route = selectedTimeSlot?.route;
  const vet = route?.vet;

  const { data } = useQuery(getTimeslotsInUserAreaQuery, {
    skip: !vet?.uid,
    variables: {
      record: {
        uid: vet?.uid,
        startDate: getStartDate(monthDate),
        endDate: moment(monthDate).endOf('month').add(1, 'week').format('YYYY-MM-DD')
      }
    }
  });

  const vetIsOccupied = useMemo(() => {
    return vet?.status === VetStatus.OCCUPIED.toUpperCase();
  }, [vet]);

  const { selectedAppointment } = useTimelineContentBox(route?.uid);

  const {
    isCalendarOpen,
    setIsCalendarOpen,
    startDate,
    workingHours,
    selectedHour,
    handleChangeDate,
    handleSelectHour,
    calendarTimeSlots
  } = useVetCard(
    vet?.uid,
    data?.getTimeslotsInUserArea,
    selectedAppointment,
    selectedVetUid,
    setSelectedVetUid,
    time,
    selectedTimeSlot,
    vet
  );

  return (
    <LoaderWrapper isTimeline isLoading={loading}>
      {!!timeline.length ? (
        <S.TimelineDataContainer singlePet={singlePet}>
          <S.PetTimelineContainer singlePet={singlePet}>
            {/* Future Timeline  */}
            {!!futureTimeline.length ? (
              futureTimeline.map((visit, i) => {
                let isSelectedTimeline = false;
                if (visit?.uid === selectedTimeSlot?.uid) {
                  isSelectedTimeline = true;
                }
                return (
                  <React.Fragment key={visit.uid}>
                    <S.TimelineItem
                      id={isFutureTimeSlot(i, visit?.route) ? 'scrollElement' : null}
                      singlePet={singlePet}
                      key={visit.uid}>
                      <TimelineTimeBox isActive={true} visit={visit} singlePet={singlePet} />
                      <TimelineContentBox
                        {...visit}
                        visit={visit}
                        appointments={visit?.appointments}
                        time={time}
                        subscription={visit?.appointments?.[0]?.subscription}
                        setVetUid={setVetUid}
                        singlePet={singlePet}
                        submitCalendar={submitCalendar}
                        rescheduleAvailable={
                          isFutureTimeSlot(i, visit?.route) &&
                          isRescheduleAvailable(visit?.route?.date, time)
                        }
                        setIsVisitModalOpen={setIsVisitModalOpen}
                        setSelectedTimeSlot={setSelectedTimeSlot}
                        selectedTimeSlot={selectedTimeSlot}
                        isSelectedTimeline={isSelectedTimeline}
                        isFutureTimeline
                      />
                    </S.TimelineItem>
                  </React.Fragment>
                );
              })
            ) : (
              <S.EmptyUpComming>
                <S.CalendarIcon src={CalendarImg} forUpcomming={true} />
                <S.EmptyUpCommingText>{t('you.dont.have.upcoming.meetings')}</S.EmptyUpCommingText>
              </S.EmptyUpComming>
            )}

            {/* Future and Past timeline separator */}
            {(!!futureTimeline.length || !!pastTimeline.length) && !isFiltered() && (
              <S.HereBoxWrapper singlePet={singlePet}>
                <S.HereBackground />
                <S.HereText singlePet={singlePet}>{t('hey.you.are.here.now')}</S.HereText>
                <S.HereBackground />
              </S.HereBoxWrapper>
            )}

            {/* Past Timeline  */}
            {!!pastTimeline.length ? (
              pastTimeline.map((visit) => {
                let isSelectedTimeline = false;
                if (visit?.uid === selectedTimeSlot?.uid) {
                  isSelectedTimeline = true;
                }
                return (
                  <React.Fragment key={visit.uid}>
                    <S.TimelineItem key={visit.uid} singlePet={singlePet}>
                      <TimelineTimeBox isActive={false} visit={visit} singlePet={singlePet} />
                      <TimelineContentBox
                        {...visit}
                        time={time}
                        setVetUid={setVetUid}
                        appointments={visit?.appointments}
                        singlePet={singlePet}
                        selectedTimeSlot={selectedTimeSlot}
                        setSelectedTimeSlot={setSelectedTimeSlot}
                        visit={visit}
                        missed={visit?.status === AppointmentRouteStatus.MISSED}
                        isSelectedTimeline={isSelectedTimeline}
                        setIsVisitModalOpen={setIsVisitModalOpen}
                      />
                    </S.TimelineItem>
                  </React.Fragment>
                );
              })
            ) : (
              <S.EmptyPast>
                <S.TextCenter>
                  <S.CalendarIcon src={CalendarDarkImg} style={{ marginTop: 0 }} />
                  <S.EmptyPastText>{t('you.dont.have.past.meetings')}</S.EmptyPastText>
                </S.TextCenter>
              </S.EmptyPast>
            )}
          </S.PetTimelineContainer>
          {singlePet && selectedTimeSlot && !isMobile && (
            <S.SelectedTimeSlotWrapper>
              <VisitInfo
                selectedTimeSlot={selectedTimeSlot}
                futureTimeline={futureTimeline}
                isRescheduleAvailable={isRescheduleAvailable}
                time={time}
                setIsVisitModalOpen={setIsVisitModalOpen}
              />
            </S.SelectedTimeSlotWrapper>
          )}
          {isVisitModalOpen && selectedTimeSlot && (
            <VisitModal
              {...selectedTimeSlot}
              onRequestClose={() => setIsVisitModalOpen(false)}
              isOpen={true}
              visitIcon={visitIcon.home}
              rescheduleAvailable={
                isFutureTimeSlot(
                  futureTimeline.findIndex((tm) => tm.uid === selectedTimeSlot?.uid),
                  selectedTimeSlot?.route
                ) &&
                isRescheduleAvailable(selectedTimeSlot?.route?.date, time) &&
                !vetIsOccupied
              }
              isFutureTimeSlot={isFutureTimeSlot(
                futureTimeline.findIndex((tm) => tm.uid === selectedTimeSlot?.uid),
                selectedTimeSlot?.route
              )}
              isFutureTimeline={
                futureTimeline.findIndex((tm) => tm.uid === selectedTimeSlot?.uid) >= 0
              }
              setIsCalendarOpen={setIsCalendarOpen}
              vetIsOccupied={vetIsOccupied}
            />
          )}

          {isCalendarOpen && (
            <TimeSlotCalendar
              month={monthDate}
              setMonthDate={setMonthDate}
              selectedDate={route?.date}
              setIsCalendarOpen={setIsCalendarOpen}
              startDate={startDate}
              handleChangeDate={handleChangeDate}
              timeSlots={calendarTimeSlots}
              workingHours={workingHours}
              handleSelectHour={handleSelectHour}
              selectedHour={selectedHour}
              handleSubmitCalendar={() => {
                submitCalendar(selectedHour?.uid, startDate, selectedTimeSlot?.uid);
                setIsCalendarOpen(false);
                setIsVisitModalOpen(false);
              }}
              isForReschedule={true}
            />
          )}
        </S.TimelineDataContainer>
      ) : (
        <NoMeetings />
      )}
    </LoaderWrapper>
  );
};

export default TimelineAppointments;
