import { Roles } from 'constants/enums';

export * from './getLatLng';

export const isPetOwnerRole = (user) => {
  return user?.role === Roles.PET_OWNER;
};

export const isVetAdminRole = (user) => {
  return user?.role === Roles.VET_ADMIN;
};

export const getPhone = (user) => {
  if (!!user?.phonePrefix && !!user.phoneNumber) {
    return `${user.phonePrefix} ${user.phoneNumber}`;
  }
  return undefined;
};
