import React, { useState } from 'react';
import * as moment from 'moment';
import clone from 'lodash/cloneDeep';
import cls from 'classnames';
import { DatePicker as AntDatePicker, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Frequency } from 'rrule';

import { RepeatMonthly } from '_fsd/features/rule-picker-repeat-monthly';
import { cssText as text } from '_fsd/shared';

import { RepeatWeekly } from './RepeatWeekly';
import css from './RecurringTimeSlotForm.module.css';
import { processDate } from '../../../../pages/vet/new-appointment-modal/serializers';

export const RecurringTimeSlotRuleEditForm = ({ rrule, setDate, setRrule, isCopy = false }) => {
  const { t } = useTranslation();

  const [optionSelected, setOptionSelected] = useState(rrule.options.freq);
  const [endsOptionSelected, setEndsOptionSelected] = useState(
    rrule.options.count ? 'after' : rrule.options.until ? 'on_date' : 'never'
  );

  const handleStartsFromChange = (value) => {
    rrule.options.dtstart = moment(moment(value).format('YYYYMMDD') + 'T120000Z').toDate();
    rrule.origOptions.dtstart = moment(moment(value).format('YYYYMMDD') + 'T120000Z').toDate();

    setRrule(rrule);
    setDate(processDate(value));
  };

  const handleIntervalChange = (value) => {
    rrule.options.interval = value;
    rrule.origOptions.interval = value;

    setRrule(rrule);
  };

  const handleRepeatWeeklyChange = (value) => {
    const weekdaysToNumbers = value.map((day) => {
      const dayNumber = moment().day(day).day();
      return dayNumber === 0 ? 6 : dayNumber - 1;
    });
    rrule.options.byweekday = weekdaysToNumbers;
    rrule.origOptions.byweekday = weekdaysToNumbers;

    setRrule(rrule);
  };
  const handleRepeatMonthlyChange = (value) => {
    const r = clone(rrule);
    r.options = {
      ...rrule.options,
      ...value
    };
    r.origOptions = {
      ...rrule.origOptions,
      ...value
    };

    setRrule(r);
  };

  const handleFrequencyChange = (value) => {
    rrule.options.freq = value;
    rrule.origOptions.freq = value;

    setRrule(rrule);
  };

  const handleAfterChange = (value) => {
    rrule.options.count = value;
    rrule.origOptions.count = value;

    setRrule(rrule);
  };

  const handleOnDateChange = (value) => {
    rrule.options.until = value
      ? moment(moment(value).format('YYYYMMDD') + 'T120000Z').toDate()
      : undefined;
    rrule.origOptions.until = value
      ? moment(moment(value).format('YYYYMMDD') + 'T120000Z').toDate()
      : undefined;

    setRrule(rrule);
  };

  return (
    <>
      <div className={css.formRow}>
        <div className={css.formItem}>
          <span className={cls(text.s12h20w4)}>{t('recurring_time_slot.repeat_every')}</span>
          <InputNumber
            className={css.inputRepeat}
            placeholder={1}
            min={1}
            onChange={(value) => handleIntervalChange(value)}
            defaultValue={rrule.options.interval}
          />
        </div>
        <div className={cls(css.formItem, css.repeatType)}>
          <span className={cls(text.s12h20w4)} />
          <Select
            defaultValue={rrule.options.freq}
            options={[
              {
                value: Frequency.DAILY,
                label: 'Days'
              },
              {
                value: Frequency.WEEKLY,
                label: 'Weeks'
              },
              {
                value: Frequency.MONTHLY,
                label: 'Months'
              }
            ]}
            onSelect={(value) => {
              setOptionSelected(value);
              handleFrequencyChange(value);
              if (value !== Frequency.WEEKLY) handleRepeatWeeklyChange([]);
            }}
          />
        </div>
      </div>
      {optionSelected === Frequency.WEEKLY && (
        <div className={css.rowWeekly}>
          <RepeatWeekly
            byweekday={rrule.options.byweekday ?? []}
            onChange={handleRepeatWeeklyChange}
          />
        </div>
      )}
      {optionSelected === Frequency.MONTHLY && (
        <RepeatMonthly value={rrule} onChange={handleRepeatMonthlyChange} />
      )}
      <div className={css.formItem}>
        <span className={cls(text.s12h20w4)}>{t('recurring_time_slot.starts_from')}</span>
        <AntDatePicker
          onChange={(value) => handleStartsFromChange(value)}
          style={{ width: '100%' }}
          picker="date"
          format={'YYYY-MM-DD'}
          defaultValue={moment(rrule.options.dtstart)}
        />
      </div>
      <div className={css.formRow}>
        <div className={css.formItem}>
          <span className={cls(text.s12h20w4)}>{t('recurring_time_slot.ends')}</span>
          <Select
            defaultValue={() => {
              if (rrule.options.count) {
                return 'after';
              } else if (rrule.options.until) {
                return 'on_date';
              }
              return 'never';
            }}
            options={[
              { value: 'never', label: 'Never' },
              { value: 'after', label: 'After' },
              { value: 'on_date', label: 'On Date' }
            ]}
            onSelect={(value) => {
              setEndsOptionSelected(value);
              if (value === 'on_date') {
                handleOnDateChange(moment().add(1, 'months').toDate());
                handleAfterChange(undefined);
              } else if (value === 'after') {
                handleAfterChange(1);
                handleOnDateChange(undefined);
              } else {
                handleAfterChange(undefined);
                handleOnDateChange(undefined);
              }
            }}
          />
        </div>
        <div className={css.formItem}>
          <span className={cls(text.s12h20w4)} />
          {endsOptionSelected === 'on_date' && (
            <AntDatePicker
              onChange={(value) => handleOnDateChange(value)}
              style={{ width: '100%' }}
              picker="date"
              format={'YYYY-MM-DD'}
              defaultValue={moment(rrule.options.until)}
            />
          )}
          {endsOptionSelected === 'after' && (
            <InputNumber
              className={css.inputFullWidth}
              placeholder={1}
              min={1}
              defaultValue={rrule.options.count}
              onChange={(value) => handleAfterChange(value)}
            />
          )}
        </div>
      </div>
    </>
  );
};
