import React, { memo } from 'react';
import DP from 'react-datepicker';
import startCase from 'lodash/startCase';
import Selector from 'components/common/selector/Selector';
import Input from 'components/common/input/Input';

import useDateFormat from 'common_repo/components/useDateFormat';
import { ReactComponent as LeftArrow } from 'assets/icons/arrow-left-blue.svg';
import { range, months } from 'utils/helpers';
import { useLocalizationContext } from 'common_repo';
import css from './Calendar.module.css';
import cls from 'classnames';
import moment from 'moment';
import { LoaderOnContent } from '../loader-on-content/LoaderOnContent';

const getYers = () => range(new Date().getFullYear(), new Date().getFullYear() + 2, 'asc');

export const Calendar = ({
  disabled,
  setMonthDate,
  value,
  label,
  theme = 'black',
  error,
  hideHeader,
  setIsCalendarOpen = () => {},
  startYear = 1900,
  endYear = 0,
  className,
  loading,
  ...props
}) => {
  return (
    <div
      className={cls(css.wrapper, className, {
        [css.themeLight]: theme === 'light',
        [css.themeBlack]: theme === 'black'
      })}>
      {loading && (
        <div className={css.loaderContainer}>
          <LoaderOnContent small />
        </div>
      )}
      <DP
        {...props}
        calendarClassName={css.calendar}
        wrapperClassName={css.section}
        onCalendarOpen={() => {
          setIsCalendarOpen(true);
        }}
        onCalendarClose={() => {
          setIsCalendarOpen(false);
        }}
        customInput={
          <Input
            className={css.input}
            label={label}
            error={error}
            isCalendarInput
            hideKeyboardOnFocus
          />
        }
        onMonthChange={setMonthDate}
        useWeekdaysShort
        renderCustomHeader={(props) => (
          <CustomHeader
            {...props}
            disabled={disabled}
            setMonthDate={setMonthDate}
            hideHeader={hideHeader}
          />
        )}
      />
    </div>
  );
};

const CustomHeader = ({
  date,
  setMonthDate,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  hideHeader,
  disabled
}) => {
  const { dateFormat } = useDateFormat();
  const { rtl: isRtl } = useLocalizationContext();
  const years = getYers();

  return (
    <div className={css.dp_header}>
      {!hideHeader && (
        <div className={css.calendar_header_wrapper}>
          <div className={css.calendar}>Calendar</div>
          <div className={css.selectedDate}>{dateFormat(date)}</div>
        </div>
      )}
      <div className={css.headerBottomWrapper}>
        <div className={css.monthSelectorWrapper}>
          <button
            className={cls(css.arrow, { [css.arrowDisabled]: prevMonthButtonDisabled || disabled })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              decreaseMonth(e);
              setMonthDate(moment(date).subtract(1, 'm').toDate());
            }}
            disabled={prevMonthButtonDisabled || disabled}>
            <LeftArrow />
          </button>
          <div className={css.month}>{startCase(months[new Date(date).getMonth()])}</div>
          <button
            className={cls(css.arrow, css.arrowRight, {
              [css.arrowDisabled]: nextMonthButtonDisabled || disabled
            })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              increaseMonth(e);
              setMonthDate(moment(date).add(1, 'm').toDate());
            }}
            disabled={nextMonthButtonDisabled || disabled}>
            <LeftArrow />
          </button>
        </div>
        <div className={css.selectorWrapper}>
          <Selector
            disabled={disabled}
            value={{
              label: new Date(date).getFullYear(),
              value: new Date(date).getFullYear()
            }}
            options={years}
            onChange={(item) => {
              changeYear(item.value);
              setMonthDate(moment(date).set('year', item.value).toDate());
            }}
            hideLabel
            noError
            isForTimeSlot
            isRtl={isRtl}
          />
        </div>
      </div>
    </div>
  );
};
