import React, { useState } from 'react';
import { RecurringTimeSlotForm } from '_fsd/widgets/recurring-timeslot-form';
import css from './VetAdminTimeSlotsPage.module.css';
import { Modal } from '_fsd/shared';
import { RemoveRecurringTimeSlot } from '_fsd/features/recurring-timeslot-remove';
import { useRemoveRecurringTimeSlot, useTimeSlotsByVetId } from '_fsd/entities/timeslot';
import { VetListFilter } from '_fsd/widgets/vet-list-filter';
import { RecurringTimeslotListCreatable } from '_fsd/widgets';

export const VetAdminTimeSlotsPage = (props) => {
  const [isRecurringTimeSlotPageOpen, setIsRecurringTimeSlotPageOpen] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isCopying, setIsCopying] = useState(false);
  const [vetId, setVetId] = useState(null);
  const [timeSlotIdToDelete, setTimeSlotIdToDelete] = useState(null);

  const {
    data: timeSlots,
    loading,
    refetch: refetchTimeSlots
  } = useTimeSlotsByVetId({ vetId, isRecurring: true, isArchived: false });

  const { handleRemoveTimeSlot } = useRemoveRecurringTimeSlot({
    timeSlotIdToDelete,
    refetch: refetchTimeSlots
  });

  return (
    <>
      {isRecurringTimeSlotPageOpen ? (
        <RecurringTimeSlotForm
          selectedTimeSlot={selectedTimeSlot}
          goBack={() => {
            setIsRecurringTimeSlotPageOpen(false);
            setSelectedTimeSlot(null);
            setIsCopying(false);
          }}
          vetId={vetId}
          refetchTimeSlots={refetchTimeSlots}
          isCopying={isCopying}
        />
      ) : (
        <div className={css.container}>
          <VetListFilter vetId={vetId} onChangeVetId={setVetId} />
          {vetId ? (
            <RecurringTimeslotListCreatable
              onRemove={(id) => setTimeSlotIdToDelete(id)}
              timeSlots={timeSlots}
              loading={loading}
              onClickNewRecurringTimeSlot={() => setIsRecurringTimeSlotPageOpen(true)}
              setSelectedTimeSlot={setSelectedTimeSlot}
              setIsRecurringTimeSlotPageOpen={setIsRecurringTimeSlotPageOpen}
              refetchTimeSlots={refetchTimeSlots}
              setIsCopying={setIsCopying}
            />
          ) : null}
        </div>
      )}
      {timeSlotIdToDelete && (
        <Modal
          customFooter={() => null}
          isOpen={timeSlotIdToDelete}
          force
          className={css.modal}
          onClose={() => setTimeSlotIdToDelete(null)}>
          <RemoveRecurringTimeSlot
            onSubmit={() => {
              handleRemoveTimeSlot(timeSlotIdToDelete);
              setTimeSlotIdToDelete(null);
            }}
          />
        </Modal>
      )}
    </>
  );
};
