export const getVetName = (vet) => {
  return getName(vet?.user);
};

export const getName = (user) => {
  let name = user?.firstName;
  if (user?.lastName) {
    name += ' ' + user?.lastName;
  }
  return name;
};

export const getInitialLetters = (user) => {
  let letters = '';
  if (user?.firstName?.[0]) {
    letters += user.firstName[0].toUpperCase();
  }
  if (user?.lastName?.[0]) {
    letters += user.lastName[0].toUpperCase();
  }
  return letters;
};
