import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';

import { useLocalization, LocalizationProvider, GlobalStyles, WizardProvider } from 'common_repo';
import useActAsUser from 'services/useActAsUser';
import useFontsLoaded from 'services/useFontsLoaded';

import PrivateRoute from 'hocs/PrivateRoute';
import PublicRoute from 'hocs/PublicRoute';
import VetRoute from 'hocs/VetRoute';

import CompleteProfile from 'pages/auth/complete-profile/CompleteProfile';
import SignUp from 'pages/auth/sign-up/SignUp';
import VerifyCode from 'pages/verify-code/VerifyCode';
import AddPet from 'pages/auth/add-pet/AddPet';

import {
  ADD_PET,
  COMPLETE_PROFILE,
  DASHBOARD,
  MY_PETS,
  SIGN_IN,
  SIGN_UP,
  VERIFY_CODE,
  PROFILE,
  FINANCE,
  SETTINGS,
  HELP,
  PETS,
  CHOOSE_PET,
  SELECT_VET,
  MEMBERSHIP_DETAILS,
  CHECKOUT,
  SINGLE_PET,
  PRIVACY_POLICY,
  TERMS,
  VET_ROUTES,
  CALENDAR,
  BOOK,
  ROUTES,
  SHOP,
  SIGN_IN_VET_ADMIN,
  VET_ADMIN_ROUTES,
  SIGN_IN_USER,
  SIGN_IN_VET
} from 'constants/client';

import { Roles } from 'constants/enums';

import Finance from 'pages/finance/Finance';
import Settings from 'pages/settings/Settings';
import PetsPage from 'pages/finance/pets-page/PetsPage';
import Help from 'pages/help/Help';
import MyPets from 'pages/my-pets/MyPets';
import OnboardingLayout from 'components/onboarding-layout/OnboardingLayout';
import NotFound from 'pages/not-found/NotFound';
import Profile from 'pages/profile/Profile';
import Layout from 'components/layout/Layout.jsx';
import VetLayout from 'components/vet-components/layout/Layout.jsx';
import ChoosePet from 'pages/membership/choose-pet/ChoosePet';
import MemberShipDetails from 'pages/membership/details/MembershipDetails';
import MembershipLayout from 'components/membership/membership-layout/MembershipLayout';
import Checkout from 'pages/membership/checkout/Checkout';
import SelectVet from 'pages/membership/select-vet/SelectVet';
import Dashboard from 'pages/dashboard/Dashboard';
import SinglePet from 'pages/single-pet/SinglePet';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import Terms from 'pages/terms/Terms.jsx';
import PrivacyPolicy from 'pages/privacy-policy/PrivacyPolicy';
import VetDashboard from 'pages/vet/dashboard/VetDashboard';
import VetProfile from 'pages/vet/vet-profile/VetProfile';
import Customers from 'pages/vet/customers/Customers';
import EditPetOwner from 'pages/vet/edit-pet-owner/EditPetOwner';
import Routes from 'pages/vet/routes/Routes';
import Calendar from 'pages/calendar/Calendar';
import Preferences from './pages/preferences/Preferences';
import AuthContext from './contexts/AuthContext';
import Shop from './pages/shop/Shop';
import SignInVetAdmin from './pages/auth/signin-vet-admin/SignInVetAdmin';
import VetAdminRoute from './hocs/VetAdminRoute';
import { SigninOptions } from 'pages/auth/signin-options';
import {
  VetCaregiversPage,
  VetClientsPage,
  VetSchedulePage,
  WorkingAreaPage,
  IntegrationsPage,
  VetApptManagerPage,
  VetAdminTimeSlotsPage,
  VetTimeSlotsPage,
  VetAdminDashboard,
  SupportedServicesPage
} from './_fsd/pages';

function App() {
  const localization = useLocalization();
  const { user, loading } = useContext(AuthContext);
  const { isFontListLoaded } = useFontsLoaded();
  const rtl = localization.rtl;

  const location = useLocation();
  const isDashboard = location.pathname === DASHBOARD;

  useActAsUser();

  useEffect(() => {
    if (user) {
      if (window?.LogRocket) {
        window.LogRocket.startNewSession();
        window.LogRocket.identify(user.uid, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          role: user.role,
          phone: `${user.phonePrefix}${user.phoneNumber}`
        });
      }
      if (user?.role?.toLowerCase() === Roles.VET?.toLowerCase()) {
        global.userRoleGlobal('Vet');
      } else global.userRoleGlobal(false);
    }
  }, [user]);

  const isUnknownRoutes = Object.keys(ROUTES).find((route) => {
    return window.location.pathname.split('/')[1] === ROUTES[route].toLowerCase().split('/')[1];
  });

  return (
    <div dir={rtl ? 'rtl' : 'ltr'}>
      <StyleSheetManager stylisPlugins={rtl ? [rtlPlugin] : []}>
        <WizardProvider>
          <GlobalStyles isDashboard={isDashboard} />
          {isFontListLoaded && (
            <LocalizationProvider value={localization}>
              <LoaderWrapper isLoading={loading}>
                <Switch>
                  <Route path={CALENDAR} exact component={Calendar} />
                  <PublicRoute
                    path={[SIGN_IN, SIGN_UP]}
                    exact
                    component={SigninOptions}
                    user={user}
                    loading={loading}
                    layout={OnboardingLayout}
                  />
                  <PublicRoute
                    path={[SIGN_IN_USER, SIGN_IN_VET]}
                    exact
                    component={SignUp}
                    user={user}
                    loading={loading}
                    layout={OnboardingLayout}
                  />
                  <PublicRoute
                    path={SIGN_IN_VET_ADMIN}
                    exact
                    component={SignInVetAdmin}
                    layout={OnboardingLayout}
                  />
                  <PublicRoute
                    path={VERIFY_CODE}
                    exact
                    component={VerifyCode}
                    user={user}
                    loading={loading}
                    layout={OnboardingLayout}
                  />
                  <PublicRoute path={TERMS} exact component={Terms} user={user} />

                  <PublicRoute path={PRIVACY_POLICY} exact component={PrivacyPolicy} user={user} />
                  <Route
                    path="/"
                    exact
                    render={() => {
                      if (!user) {
                        return <Redirect to={SIGN_IN} />;
                      } else {
                        // todo: refactor it
                        let redirect;
                        if (!user?.firstName) {
                          redirect = COMPLETE_PROFILE;
                        } else if (user?.role?.toLowerCase() === Roles?.VET.toLowerCase()) {
                          redirect = VET_ROUTES.ROUTES;
                        } else if (!user?.hasPet) {
                          redirect = ADD_PET;
                        } else {
                          return (
                            <PrivateRoute
                              path={DASHBOARD}
                              exact
                              component={Dashboard}
                              user={user}
                              loading={loading}
                              isHome={!user?.hasActiveSubscription}
                              layout={Layout}
                            />
                          );
                        }
                        if (user.role === Roles.VET_ADMIN) {
                          redirect = VET_ADMIN_ROUTES.SCHEDULE;
                        }
                        return <Redirect to={redirect} />;
                      }
                    }}
                  />

                  {user && user.role === Roles.VET_ADMIN && (
                    <>
                      <VetAdminRoute
                        path={VET_ADMIN_ROUTES.SCHEDULE}
                        exact
                        component={VetSchedulePage}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                        isAdmin
                      />
                      <VetAdminRoute
                        path={VET_ADMIN_ROUTES.CAREGIVERS}
                        exact
                        component={VetCaregiversPage}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                        isAdmin
                      />
                      <VetAdminRoute
                        path={VET_ADMIN_ROUTES.CLIENTS}
                        exact
                        component={VetClientsPage}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                        isAdmin
                      />
                      <VetAdminRoute
                        path={VET_ADMIN_ROUTES.APP_MANAGER}
                        exact
                        component={VetApptManagerPage}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                        isAdmin
                      />
                      <VetAdminRoute
                        path={VET_ADMIN_ROUTES.INTEGRATIONS}
                        exact
                        component={IntegrationsPage}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                      />
                      <VetAdminRoute
                        path={VET_ADMIN_ROUTES.SERVICE_AREA}
                        exact
                        component={WorkingAreaPage}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                        isAdmin
                      />
                      <VetAdminRoute
                        path={VET_ADMIN_ROUTES.PLANS_AND_USAGE}
                        exact
                        component={VetAdminDashboard}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                        isAdmin
                      />
                      <VetAdminRoute
                        path={VET_ADMIN_ROUTES.TIME_SLOTS}
                        exact
                        component={VetAdminTimeSlotsPage}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                        isAdmin
                      />
                      <VetAdminRoute
                        path={VET_ADMIN_ROUTES.SUPPORTED_SERVICES}
                        exact
                        component={SupportedServicesPage}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                        isAdmin
                      />
                    </>
                  )}
                  {user && user.role !== Roles.VET_ADMIN && (
                    <>
                      <VetRoute
                        path={VET_ROUTES.VET_DASHBOARD}
                        exact
                        component={VetDashboard}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                      />
                      <VetRoute
                        path={VET_ROUTES.UPDATE_VET}
                        exact
                        component={VetProfile}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                      />
                      <VetRoute
                        path={VET_ROUTES.CUSTOMERS}
                        exact
                        component={Customers}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                        isCustomers
                      />
                      <VetRoute
                        path={VET_ROUTES.EDIT_PET_OWNER}
                        exact
                        component={EditPetOwner}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                      />
                      <VetRoute
                        path={VET_ROUTES.ROUTES}
                        exact
                        component={Routes}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                      />
                      <VetRoute
                        path={VET_ROUTES.SETTINGS}
                        exact
                        component={Preferences}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                        disableMobilePadding
                      />
                      <VetRoute
                        path={VET_ROUTES.VET_TIME_SLOTS}
                        exact
                        component={VetTimeSlotsPage}
                        user={user}
                        loading={loading}
                        layout={VetLayout}
                      />
                      <PrivateRoute
                        path={COMPLETE_PROFILE}
                        exact
                        component={CompleteProfile}
                        user={user}
                        loading={loading}
                        layout={OnboardingLayout}
                      />
                      {user?.firstName && user?.hasPet && (
                        <>
                          <PrivateRoute
                            path={SHOP}
                            exact
                            component={Shop}
                            user={user}
                            loading={loading}
                            layout={Layout}
                          />
                          <PrivateRoute
                            path={BOOK}
                            exact
                            component={Calendar}
                            user={user}
                            loading={loading}
                            layout={Layout}
                          />
                          <PrivateRoute
                            path={PROFILE}
                            exact
                            component={Profile}
                            user={user}
                            loading={loading}
                            layout={Layout}
                          />
                          <PrivateRoute
                            path={SETTINGS}
                            exact
                            component={Settings}
                            user={user}
                            loading={loading}
                            layout={Layout}
                          />
                          <PrivateRoute
                            path={FINANCE}
                            exact
                            component={Finance}
                            user={user}
                            loading={loading}
                            layout={Layout}
                          />
                          <PrivateRoute
                            path={PETS}
                            exact
                            component={PetsPage}
                            user={user}
                            loading={loading}
                            layout={Layout}
                          />
                          <PrivateRoute
                            path={SINGLE_PET}
                            exact
                            component={SinglePet}
                            user={user}
                            loading={loading}
                            layout={Layout}
                          />
                          <PrivateRoute
                            path={HELP}
                            exact
                            component={Help}
                            user={user}
                            loading={loading}
                            layout={Layout}
                          />
                          <Route
                            exact
                            path={[CHOOSE_PET, MEMBERSHIP_DETAILS, SELECT_VET, CHECKOUT]}>
                            <Layout>
                              <PrivateRoute
                                path={CHOOSE_PET}
                                exact
                                component={ChoosePet}
                                user={user}
                                loading={loading}
                                layout={MembershipLayout}
                              />
                              <PrivateRoute
                                path={MEMBERSHIP_DETAILS}
                                exact
                                component={MemberShipDetails}
                                user={user}
                                loading={loading}
                                layout={MembershipLayout}
                              />
                              <PrivateRoute
                                path={SELECT_VET}
                                exact
                                component={SelectVet}
                                user={user}
                                loading={loading}
                                layout={MembershipLayout}
                              />
                              <PrivateRoute
                                path={CHECKOUT}
                                exact
                                component={Checkout}
                                user={user}
                                loading={loading}
                                layout={MembershipLayout}
                              />
                            </Layout>
                          </Route>
                        </>
                      )}
                      <PrivateRoute
                        path={ADD_PET}
                        exact
                        component={AddPet}
                        user={user}
                        loading={loading}
                        layout={OnboardingLayout}
                      />
                      <PrivateRoute
                        path={MY_PETS}
                        exact
                        component={MyPets}
                        user={user}
                        loading={loading}
                        layout={OnboardingLayout}
                      />
                      {!isUnknownRoutes && <Route path="*" component={NotFound} />}
                    </>
                  )}
                  <Route path="*" component={NotFound} />
                </Switch>
              </LoaderWrapper>
            </LocalizationProvider>
          )}
        </WizardProvider>
      </StyleSheetManager>
    </div>
  );
}

export default App;
