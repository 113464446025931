import { useMemo } from 'react';
import { useVets } from '_fsd/entities/vet';
import { useQuery } from '@apollo/client';
import { getFilteredTimeSlotsByVetUidQuery } from 'gql';
import moment from 'moment-timezone';
import { spreadTimeslot, processDate } from '_fsd/entities/timeslot';
import { TimeslotsFilterSort } from 'constants/enums';
import orderBy from 'lodash/orderBy.js';

export const useRescheduleData = ({
  selectedSort,
  timeslotActions,
  filters,
  vetId,
  currentAddress,
  selectedDate
}) => {
  const { data: vets } = useVets();

  const startDate = moment(selectedDate.format())
    .parseZone()
    .tz('UTC', true)
    .startOf('day')
    .format('YYYY-MM-DD HH:mm');
  const endDate = moment(moment(selectedDate.format()).endOf('month').format())
    .parseZone()
    .tz('UTC', true)
    .endOf('day')
    .format('YYYY-MM-DD HH:mm');
  const { data, loading: loadingTimeslots } = useQuery(getFilteredTimeSlotsByVetUidQuery, {
    variables: {
      data: {
        uid: vetId,
        userAddress: currentAddress,
        startDate,
        endDate,
        isFull: true,
        isLocked: true,
        filterByAddress: !filters.displayTSNotInArea
      }
    },
    fetchPolicy: 'network-only'
  });

  const rawSlots = useMemo(() => {
    if (data?.getFilteredTimeSlotsByVetUid?.length) {
      const timeslots = [];
      data.getFilteredTimeSlotsByVetUid.forEach((rs) => {
        const slots = spreadTimeslot({
          timeslot: rs.timeSlot,
          startDate: moment(selectedDate.format()).parseZone().tz('UTC', true).toDate(),
          endDate: moment(moment(selectedDate.format()).endOf('month').format())
            .parseZone()
            .tz('UTC', true)
            .toDate()
        });
        slots.forEach((s) => {
          timeslots.push({
            ...rs,
            routes: rs.routes?.filter((r) => r.route.date === s.date),
            timeSlot: s
          });
        });
      });
      return timeslots;
    }
    return [];
  }, [data?.getFilteredTimeSlotsByVetUid]);

  const { filteredTimeslots, dates } = useMemo(() => {
    if (rawSlots.length) {
      const filtered = rawSlots
        .filter((t) => {
          if (!filters.displayFullTS && t.routes?.[0]?.isFull) return false;
          if (!filters.displayLocked && t.routes?.[0]?.isLocked) return false;
          if (!filters.selectedVets.includes(t.timeSlot.vet.uid)) return false;
          return true;
        })
        .map((t) => ({
          ...t,
          timeSlot: {
            ...t.timeSlot,
            date: processDate(t.timeSlot.date)
          }
        }));
      if (!filtered.some((t) => t.timeSlot._key === timeslotActions.activeTimeslot?._key)) {
        timeslotActions.setActiveTimeslot();
      }
      const dates = {};
      let sorted = filtered.map((ts) => {
        if (!dates[ts.timeSlot.date]) {
          dates[ts.timeSlot.date] = [];
        }
        dates[ts.timeSlot.date].push(ts);
        const additionalDistanceInMi = ts?.routes?.[0]
          ? ts?.routes?.[0].distance ?? ts.defaultDistance
          : ts.defaultDistance;
        const additionalTime = ts?.routes?.[0]
          ? ts?.routes?.[0].additionalTime ?? ts.defaultAdditionalTime
          : ts.defaultAdditionalTime;
        return {
          ...ts,
          additionalTime,
          additionalDistanceInMi
        };
      });
      switch (selectedSort.value) {
        case TimeslotsFilterSort.SUGGESTED:
          sorted = orderBy(
            sorted,
            [
              'additionalTime',
              (item) => moment(item.timeSlot.date, 'YYYY-MM-DD').toDate().getTime(),
              'startTime'
            ],
            ['asc', 'asc', 'asc']
          );
          break;
        case TimeslotsFilterSort.DISTANCE_NEAREST:
          sorted = orderBy(
            sorted,
            [
              'additionalDistanceInMi',
              (item) => moment(item.timeSlot.date, 'YYYY-MM-DD').toDate().getTime(),
              'startTime'
            ],
            ['asc', 'asc', 'asc']
          );
          break;
        case TimeslotsFilterSort.DATE_EARLIEST:
          sorted = orderBy(
            sorted,
            [
              (item) => moment(item.timeSlot.date, 'YYYY-MM-DD').toDate().getTime(),
              'additionalDistanceInMi',
              'startTime'
            ],
            ['asc', 'asc', 'asc']
          );
      }
      return { filteredTimeslots: sorted, dates };
    }
    return { filteredTimeslots: [], dates: {} };
  }, [
    rawSlots,
    timeslotActions.activeTimeslot?._key,
    filters.displayFullTS,
    filters.displayLocked,
    filters.selectedVets,
    selectedSort
  ]);

  return { filteredTimeslots, vets, loadingTimeslots, dates };
};
