import React, { memo, useContext } from 'react';
import PersonalDetails from '../../../components/calendar/steps/personal-details/PersonalDetails';
import ScheduleAppointmentContext from '../ScheduleAppointmentContext';
import AuthContext from '../../../contexts/AuthContext';
import { isPetOwnerRole } from '../../../_fsd/entities';

const StepPersonalDetails = ({
  form,
  errors,
  handleReassignEmail,
  disableEmail = true,
  disablePhone = true,
  isPetOwner
}) => {
  return (
    <PersonalDetails
      form={form}
      disablePhone={disablePhone}
      disableEmail={disableEmail}
      errors={errors}
      handleReassignEmailButton={handleReassignEmail}
      isPetOwner={isPetOwner}
    />
  );
};

const Memoized = memo(StepPersonalDetails);

const usePropsPerRole = (isForVet, form) => {
  if (isForVet) return { form, disablePhone: false, disableEmail: false };
  const props = useContext(ScheduleAppointmentContext);
  const { user } = useContext(AuthContext);
  const isPetOwner = isPetOwnerRole(user);
  return {
    ...props,
    disableEmail: !!user?.email,
    isPetOwner
  };
};
const Container = ({ isForVet, form }) => {
  const props = usePropsPerRole(isForVet, form);
  return <Memoized {...props} />;
};

export default Container;
