import React from 'react';
import cn from 'classnames';
import { cssText } from '_fsd/shared';
import css from './PetOwnerShortRow.module.css';
import { Row } from 'antd';
import { UserContactInfoPopover } from '_fsd/features/user-contact-info-popover/ui/UserContactInfoPopover.jsx';
import { getPhone } from '../../../user';

export const PetOwnerShortRow = ({ user, name, address, viewMode, onClickAddress }) => (
  <Row align={viewMode ? 'top' : 'bottom'} style={{ flexDirection: viewMode ? 'column' : 'row' }}>
    <UserContactInfoPopover phone={getPhone(user)} email={user.email}>
      <span className={cn(css.client_name, cssText.s16h25w6, css.mr24)}>{name}</span>
    </UserContactInfoPopover>
    <span
      onClick={() => {
        !viewMode && onClickAddress();
      }}
      className={cn(css.address, cssText.s14h22w4, { [css.short]: viewMode })}>
      {address}
    </span>
  </Row>
);
