import { addressSerializer } from '_fsd/features/timeslot-editor/lib';
import { processDate } from '../../../../pages/vet/new-appointment-modal/serializers';

export const serializeTimeslotEntity = (timeslot) => {
  return {
    uid: timeslot.uid,
    date: timeslot.date,
    vet: timeslot.vet,
    rule: timeslot.rule,
    type: timeslot.type,
    name: timeslot.name,
    startTime: timeslot.startTime,
    endTime: timeslot.endTime,
    isRecurring: timeslot.isRecurring,
    isSingle: timeslot.isSingle,
    maxAppointments: timeslot.maxAppointments,
    weekDay: timeslot.weekDay,
    startPoint: timeslot.startPoint,
    endPoint: timeslot.endPoint,

    createdAt: timeslot.createdAt,
    updatedAt: timeslot.updatedAt,

    routes: timeslot.routes,
    workingAreas: timeslot.workingAreas
  };
};

export const serializeTimeslotUpdateInput = (timeslot, { isCopying } = {}) => {
  const pureObject = {
    type: timeslot.type,
    date: processDate(timeslot.date),
    name: timeslot.name,
    rule: timeslot.rule,
    startTime: timeslot.startTime,
    endTime: timeslot.endTime,
    isIncludeChecked: timeslot.isIncludeChecked,
    isRecurring: timeslot.isRecurring,
    isSingle: timeslot.isSingle,
    maxAppointments: timeslot.maxAppointments,

    startPoint: addressSerializer({ address: timeslot.startPoint }),
    endPoint: addressSerializer({ address: timeslot.endPoint }),

    workingAreas: timeslot.workingAreas
  };
  if (timeslot.vet?.uid) {
    pureObject.vetId = timeslot.vet.uid;
  }
  if (!isCopying && timeslot.uid) {
    pureObject.uid = timeslot.uid;
  }
  return pureObject;
};
