import { useMutation, gql } from '@apollo/client';
import { message } from 'antd';

export const useRemoveRecurringTimeSlot = ({ timeSlotId, refetch }) => {
  const [removeTimeSlot] = useMutation(removeTimeSlotQuery, {
    variables: {
      record: { timeSlotId }
    },
    skip: !timeSlotId
  });
  const handleRemoveTimeSlot = async (timeSlotId) => {
    try {
      await removeTimeSlot({
        variables: {
          record: {
            timeSlotId
          }
        }
      });
      refetch();
    } catch (e) {
      message.error(e.message, 5000);
      console.log(e);
    }
  };

  return { handleRemoveTimeSlot };
};

const removeTimeSlotQuery = gql`
  mutation removeRecurringTimeSlot($record: RemoveTimeSlotRecord!) {
    removeRecurringTimeSlot(record: $record)
  }
`;
