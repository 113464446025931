import React from 'react';
import { useTranslation } from 'react-i18next';

import { EditOutlined } from '@ant-design/icons';
import AvatarGroup from 'components/avatar-group/AvatarGroup';
import './style.css';

const ClientCard = ({ item, onClientSelect, selectedClient, onClientEdit }) => {
  const { t } = useTranslation();
  const name =
    item?.user?.firstName || item?.user?.lastName
      ? `${item?.user?.firstName}` + (item?.user?.lastName ? ` ${item?.user?.lastName}` : '')
      : `${item?.user?.phonePrefix}${item?.user?.phoneNumber}`;
  return (
    <div
      className={`client-card-wrapper ${selectedClient?.user?.uid === item.user?.uid ? 'active' : ''}`}
      onClick={() => onClientSelect(item)}>
      {item.pets?.length > 0 && (
        <div style={{ marginRight: '2.4rem' }}>
          <AvatarGroup pets={item.pets} />
        </div>
      )}
      <div className="client-details-wrapper address-details">
        <h3>{name}</h3>
        <div className="address-wrapper">
          <h4 onClick={(e) => onClientEdit(e, item?.user)}>{t('new_appointment.address')}</h4>
          {item?.user?.address ? (
            <h5 onClick={(e) => onClientEdit(e, item?.user)}>{item?.user?.address?.description}</h5>
          ) : (
            <h5>
              <div onClick={(e) => onClientEdit(e, item?.user)} className="add-address-button">
                {t('new_appointment.add_address')}
              </div>
            </h5>
          )}
        </div>
        {item?.user?.address ? (
          <h5>
            {item?.user?.address?.city}, {item?.user?.address?.country?.name}{' '}
            <EditOutlined onClick={(e) => onClientEdit(e, item?.user)} />
          </h5>
        ) : null}
      </div>
    </div>
  );
};

export default ClientCard;
