import * as yup from 'yup';

export const clientSchema = yup.object({
  firstName: yup.string().required('The field is required'),
  lastName: yup.string(),
  email: yup.string().email(),
  phonePrefix: yup.string(),
  phoneNumber: yup.string().min(8, 'Please enter a valid phone number'),
  address: yup.string(),
  addressObject: yup.object()
});
