import { rrulestr } from 'rrule';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import { processDate } from '../../../../pages/vet/new-appointment-modal/serializers';

export const spreadTimeslot = ({
  timeslot,
  startDate = moment().startOf('month').toDate(),
  endDate = moment().endOf('month').toDate()
}) => {
  const timeslots = [];
  rrulestr(timeslot.rule).between(startDate, endDate, false, (date) => {
    date.setHours(Number(timeslot?.startTime.slice(0, timeslot?.startTime.length - 6)));
    date.setMinutes(Number(timeslot?.startTime.slice(3, timeslot?.startTime.length - 3)));
    timeslots.push({
      ...timeslot,
      date: processDate(date),
      _key: uniqueId('ui_route_uid_')
    });
    return date;
  });
  return timeslots;
};
