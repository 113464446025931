import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import cls from 'classnames';
import { message } from 'antd';
import css from './Reschedule.module.css';
import { useMutation } from '@apollo/client';
import { rescheduleVisitMutation } from 'gql';
import { Calendar, Button, Label, cssText as text, getStartDate } from '_fsd/shared';
import { TimeslotCalendar as Timeslot } from './Timeslot';
import { Providers } from './Providers';
import petSvg from 'assets/icons/pet.svg';
import { FilterOption } from './FilterOption';
import { useTranslation } from 'react-i18next';
import { useRescheduleData } from '../../api';

const DATE_FORMAT_FULL = 'YYYY-MM-DD';

export const Reschedule = (props) => {
  const {
    timeslotActions,
    filters,
    theme,
    displayFooter,
    appointment,
    close,
    currentAddress,
    isVetAdmin,
    vetId
  } = props;
  const { t } = useTranslation('reschedule');
  const [isLoading, setLoading] = useState(false);

  const [monthDate, setMonthDate] = useState(timeslotActions.calendarDate);

  const {
    filteredTimeslots,
    vets,
    dates,
    loadingTimeslots: loading
  } = useRescheduleData({
    selectedSort: 'suggested',
    selectedDate: moment(getStartDate(monthDate)).startOf('day'),
    timeslotActions,
    filters,
    vetId,
    currentAddress
  });

  const selectedDate = timeslotActions.calendarDate.format(DATE_FORMAT_FULL);

  const timeslotsInDate = useMemo(() => {
    if (filteredTimeslots && !loading && selectedDate) {
      return dates[selectedDate]?.map((f) => f.timeSlot) || [];
    }
    return [];
  }, [dates, selectedDate, loading]);

  const [reschedule] = useMutation(rescheduleVisitMutation);
  const rescheduleVisit = () => {
    setLoading(true);
    reschedule({
      variables: {
        record: {
          date: moment(timeslotActions.calendarDate).format('YYYY-MM-DD'),
          appointmentId: appointment.uid,
          timeSlotId: timeslotActions.activeTimeslot?.uid
        }
      },
      refetchQueries: ['getCalendarEvents', 'getTimeSlots', 'getAppointmentsByDate']
    })
      .then(() => {
        setLoading(false);
        message.success('Appointment rescheduled successfully');
        close();
      })
      .catch((e) => {
        message.error(e.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!timeslotActions.activeTimeslot) {
      timeslotActions.setActiveTimeslot(timeslotsInDate[0]);
    }
  }, [timeslotsInDate]);

  return (
    <>
      <div className={cls('flex-row', css.content, { [css.noPadding]: theme === 'light' })}>
        <div className={cls('flex-column', css.left_column)}>
          <div className={cls('flex-column', css.checkboxes)}>
            <span className={cls(css.filters, text.s16w6l18)}>Filters</span>
            <FilterOption
              disabled={isLoading}
              value={filters.displayFullTS}
              setValue={filters.setFullTS}
              label={t('show_full_ts')}
            />
            <FilterOption
              disabled={isLoading}
              value={filters.displayLocked}
              setValue={filters.setDisplayLocked}
              label={t('show_locked_ts')}
            />
            <FilterOption
              disabled={isLoading}
              value={filters.displayTSNotInArea}
              setValue={filters.setDisplayTSNotInArea}
              label={t('show_all_areas')}
            />
            {isVetAdmin ? null : (
              <FilterOption
                disabled={isLoading}
                value={filters.displayVets}
                setValue={filters.setDisplayVets}
                label={t('show_other_vets')}
              />
            )}
          </div>
          <div className={cls('flex-column', 'jc-sb')}>
            <Providers
              displayVets={filters.displayVets}
              isLoading={isLoading}
              vets={vets}
              selectedVets={filters.selectedVets}
              setSelectedVets={filters.setSelectedVets}
            />
          </div>
        </div>
        <div className={cls('flex-column', css.right_column)}>
          <Calendar
            loading={loading}
            month={monthDate}
            setMonthDate={setMonthDate}
            theme={theme}
            open
            hideHeader
            holidays={Object.keys(dates).map((date) => ({ date: `${date} 12:00:00` }))}
            endYear={2}
            minDate={new Date()}
            startYear={new Date().getFullYear()}
            selected={timeslotActions.calendarDate.toDate()}
            onChange={(value) => !isLoading && timeslotActions.setCalendarDate(moment(value))}
          />
          <div className={cls(css.timeslots)}>
            <span className={cls(css.tsranges, text.s16w6l18)}>Appointment time slot</span>
            {timeslotsInDate.length ? (
              <div className={css.rows}>
                {timeslotsInDate.map((ts) => (
                  <Timeslot
                    theme={theme}
                    key={ts.uid}
                    timeslot={ts}
                    disabled={isLoading}
                    active={ts.uid === timeslotActions.activeTimeslot?.uid}
                    onClick={timeslotActions.setActiveTimeslot}
                  />
                ))}
              </div>
            ) : (
              !loading && (
                <div className={css.petIcon}>
                  <img src={petSvg} alt="bg" />
                  <Label className={css.labelNoTimeslots}>
                    There are no timeslots available for the selected date and filters
                  </Label>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {displayFooter ? (
        <div className={css.footer}>
          {!timeslotActions.activeTimeslot && (
            <div className={cls(css.error, text.s14w4l22)}>* Please select a time-slot</div>
          )}
          <Button
            type="primary"
            colorScheme="blue"
            disabled={!timeslotActions.activeTimeslot || isLoading}
            onClick={rescheduleVisit}>
            Reschedule
          </Button>
        </div>
      ) : null}
    </>
  );
};
