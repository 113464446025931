import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getInitialLetters } from '../../utils/helpers';

import useVetCard from './useVetCard';

import Swiper from 'components/common/swiper/Swiper';
import Language from 'components/language/Language';
import TimeSlot from 'components/time-slot/TimeSlot';
import TimeSlotCalendar from 'components/time-slot/TimeSlotCalendar';

import * as S from './components';

const VetCard = ({
  uid,
  user,
  languages,
  timeSlots,
  submitCalendar,
  selectedAppointment,
  timeSlotId,
  selectedVetUid,
  setSelectedVetUid,
  fullTimeSlots,
  time
}) => {
  const {
    isCalendarOpen,
    setIsCalendarOpen,
    startDate,
    workingHours,
    selectedHour,
    currentMonthTimeSlots,
    handleChangeDate,
    handleSubmitCalendar,
    handleOpenCalendar,
    handleSelectHour,
    setDate,
    calendarTimeSlots
  } = useVetCard(
    uid,
    timeSlots,
    submitCalendar,
    selectedAppointment,
    selectedVetUid,
    setSelectedVetUid,
    fullTimeSlots,
    time
  );

  const { t } = useTranslation();
  return (
    <>
      <S.Wrapper
        isSelected={
          selectedVetUid &&
          selectedVetUid === uid &&
          selectedHour &&
          selectedHour.uid === timeSlotId
        }>
        <S.MainContent>
          <S.Flex>
            {user.avatarUrl ? (
              <S.Avatar src={user?.avatarUrl} />
            ) : (
              <S.AvatarWithText>
                {getInitialLetters(null, user?.firstName, user?.lastName)}
              </S.AvatarWithText>
            )}
            <S.ResponsiveName>
              {user?.firstName} {user?.lastName}
            </S.ResponsiveName>
          </S.Flex>
          <S.Info>
            <S.Name>
              {user?.firstName} {user?.lastName}
            </S.Name>
            <S.LanguagesWrapper>
              {languages.map((l) => (
                <S.LanguageWrapper key={l.code}>
                  <Language language={l} />
                </S.LanguageWrapper>
              ))}
            </S.LanguagesWrapper>
            <S.About>{user?.bio}</S.About>
          </S.Info>
        </S.MainContent>
        <S.TimeSlots>
          {!!currentMonthTimeSlots.length && (
            <Swiper>
              {currentMonthTimeSlots.map((slot) => {
                return (
                  <TimeSlot
                    slot={slot}
                    key={slot.uid}
                    setDate={setDate}
                    isActive={
                      slot.isActive &&
                      selectedHour &&
                      selectedHour.uid == slot.uid &&
                      dayjs(selectedHour.date).format('YYYYMMDDhhmm') ===
                        dayjs(slot.date).format('YYYYMMDDhhmm') &&
                      selectedVetUid == uid
                    }
                  />
                );
              })}
            </Swiper>
          )}
        </S.TimeSlots>
        <S.MoreTimeSlots onClick={handleOpenCalendar}>{t('not.find.slot')}</S.MoreTimeSlots>
      </S.Wrapper>
      {isCalendarOpen && (
        <TimeSlotCalendar
          setIsCalendarOpen={setIsCalendarOpen}
          startDate={startDate}
          handleChangeDate={handleChangeDate}
          timeSlots={calendarTimeSlots}
          workingHours={workingHours}
          handleSelectHour={handleSelectHour}
          selectedHour={selectedHour}
          handleSubmitCalendar={handleSubmitCalendar}
        />
      )}
    </>
  );
};

export default VetCard;
