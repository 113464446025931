import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { cssText, cssSpace, UserDynamicAvatar } from '_fsd/shared';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import css from './VetListFilter.module.css';

import { useVets } from '_fsd/entities/vet';

const { Search } = Input;

export const VetListFilter = ({ vetId, onChangeVetId }) => {
  const [search, setSearch] = useState('');
  const setVetId = (id) => {
    onChangeVetId && onChangeVetId(id);
  };
  const { data: vets, loading: vetsLoading } = useVets({ variables: { search } });
  useEffect(() => {
    if (!vetId && vets?.length && !vetsLoading) {
      setVetId(vets[0].uid);
    }
  }, [vets, vetId, vetsLoading]);

  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <div className={cls(css.header, cssSpace.mb16)}>
        <span className={cssText.s16h24w5}>{t('recurring_time_slot.vet_list_title')}</span>
      </div>
      <Search
        placeholder={t('recurring_time_slot.search_caregiver_placeholder')}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={setSearch}
        className={cssSpace.mb20}
      />
      {!vetsLoading
        ? vets?.map((vet) => {
            return (
              <div
                key={vet.uid}
                className={cls(
                  css.caregiverCard,
                  cssSpace.mb12,
                  vetId === vet.uid ? css.caregiverCardSelected : undefined
                )}
                onClick={() => setVetId(vet.uid)}>
                <UserDynamicAvatar user={vet.user} size="lg" />
                <div className={cls(cssSpace.ml12, css.fullOpacity)}>
                  {vet.user.firstName} {vet.user.lastName}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};
