import React from 'react';
import { Button } from '_fsd/shared';
import { useTranslation, Trans } from 'react-i18next';
import cls from 'classnames';
import { ReactComponent as ExclamationCircleIcon } from 'assets/icons/exclamation-circle.svg';

import { cssText as text, cssSpace as space } from '_fsd/shared';
import css from './MissingInfo.module.css';
import { Col, Row } from 'antd';

export const MissingInfo = ({ onSubmit, onClose, email, phoneNumber }) => {
  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <Row wrap={false}>
        <Col>
          <ExclamationCircleIcon className={space.mr16} />
        </Col>
        <Col>
          <div className={cls(css.title, text.s16h24w5, space.mb8)}>
            {!email
              ? t('missing_info.email_title')
              : !phoneNumber
                ? t('missing_info.phone_title')
                : t('missing_info.address_title')}
          </div>
          <div className={cls(css.description, text.s14h22w4, space.mb32)}>
            {!email ? (
              t('missing_info.email_description')
            ) : !phoneNumber ? (
              <Trans
                i18nKey="missing_info.phone_description"
                components={{
                  bold: <strong />
                }}
              />
            ) : (
              t('missing_info.address_description')
            )}
          </div>
          <div className={cls(css.question, text.s14h22w4, space.mb24)}>
            {t('missing_info.go_back_question')}
          </div>
          <div className={css.buttons}>
            <Button className={cls(css.continueButton)} onClick={onSubmit} type="default">
              {t('missing_info.continue_anyway')}
            </Button>
            <Button type="primary" colorScheme="orange" onClick={onClose}>
              {t('missing_info.go_back')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};
